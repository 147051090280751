import React, { useCallback, useEffect, useState } from "react";
import { protectedAxiosInstance } from "../api/axiosManagement";
import CircledTickIcon from "../assets/CircledTickIcon";
import ErrorMessage from "./ErrorMessage";
import { Link } from "react-router-dom";
import LoadingSpinner from "../assets/images/LoadingSpinner.gif";

import JPGIcon from "../assets/svg-tsx/JPGIcon";
import ToolTip from "./ToolTip";
import JPEGIcon from "../assets/svg-tsx/JPEGIcon";
import PNGIcon from "../assets/svg-tsx/PNGIcon";
import PDFFileIcon from "../assets/svg-tsx/PDFFileIcon";

type UploadFileType = {
  label?: string;
  kind?: "PC-GSTC" | "PC-CCHEQUE";
  value?: string | undefined;
  setter?: any;
  error?: string;
  removeError?: any;
  setErrors?: any;
  disabled?: boolean;
  inputID: string;
  targetRef?: any;
  required?: any;
};
const UploadFile = ({
  label = "",
  kind,
  value,
  setter,
  error,
  setErrors,
  removeError,
  disabled = false,
  inputID = "",
  targetRef,
  required = false,
}: UploadFileType) => {
  const [fileName, setFileName] = useState<any>();
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [uploadMessage, setUploadMessage] = useState<string>("");

  const get_signed = async (extension: string) => {
    const payload = {
      extension,
      kind,
    };
    const response = await protectedAxiosInstance
      .post(`/admin/accounts/generate-upload-url`, payload)
      .then((res: any) => {
        return res.data.data;
      })
      .catch((err: any) => {
        setErrors(err?.data?.message || err?.data?.errors || []);
      });
    return response;
  };

  const signed_to_s3 = async (acceptedFile: File) => {
    setFileName(acceptedFile?.name);

    const extension = acceptedFile.name.split(".").at(-1)?.toLowerCase();

    const { file_path, upload_url } = await get_signed(extension as string);

    let contentType: string;
    // jpg, jpeg, pdf, png;
    if (extension === "jpeg" || extension === "jpg") {
      contentType = "image/jpeg";
    } else if (extension === "png") {
      contentType = "image/png";
    } else if (extension === "pdf") {
      contentType = "application/pdf";
    } else {
      contentType = "";
    }

    const response = await fetch(upload_url, {
      method: "PUT",
      body: acceptedFile,
      headers: {
        "Content-Type": contentType,
      },
    });

    return { response, file_path };
  };

  const onDrop = useCallback(async (acceptedFiles: any) => {
    removeError();
    setIsLoading(true);
    if (acceptedFiles.length > 0) {
      setErrors && setErrors([]);
    }

    const file = acceptedFiles?.[0];
    const fileName = file?.name?.toLowerCase();
    const supportedExtensions = [".jpg", ".jpeg", ".png", ".pdf"];
    // Check if the file name ends with any of the supported extensions
    if (!supportedExtensions?.some((ext) => fileName?.endsWith(ext))) {
      setErrors && setErrors("File format is not supported.");
      setIsLoading(false);
      return;
    }

    const maxFileSize = 20 * 1024 * 1024;

    if (file.size > maxFileSize) {
      setErrors && setErrors("File size exceeds the limit.");
      setIsLoading(false);
      return;
    }

    setFileName("");
    setUploadMessage("");

    try {
      const { response, file_path } = await signed_to_s3(acceptedFiles[0]);
      setUploadMessage("File uploaded successfully!");
      setter(file_path);
    } catch (error) {
      // handle error here
    } finally {
      setIsLoading(false);
      const fileInput = document.getElementById(inputID) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
    }
  }, []);

  let extension;
  if (value) {
    const parts = value.split("?");
    const urlWithoutParams = parts[0];
    const dotIndex = urlWithoutParams.lastIndexOf(".");

    if (dotIndex !== -1) {
      extension = urlWithoutParams.substring(dotIndex + 1);
    }
  }

  return (
    <div className={`w-full`} ref={targetRef}>
      {label && (
        <p className={`font-medium text-[13px] pb-1`}>
          {label}{" "}
          {required && <span className="text-red-500 text-xs ml-1">*</span>}
        </p>
      )}

      <div className="mb-1 flex items-center gap-2">
        <label
          htmlFor={inputID}
          className={`${
            disabled || isLoading
              ? "cursor-not-allowed opacity-50"
              : "cursor-pointer"
          } text-sm font-medium px-8 py-2 w-fit bg-[#e4e4e4] rounded-md whitespace-nowrap`}
        >
          <input
            type="file"
            id={inputID}
            accept=".jpeg,.jpg,.pdf,.png"
            disabled={disabled}
            onChange={(e) => onDrop(e.target.files)}
            style={{ display: "none" }}
          />
          Upload File
        </label>

        <ToolTip label="Save to see uploaded file" position="right">
          <div>
            {isLoading ? (
              <div className="flex items-center gap-1 text-sm font-gilroy-semi-bold overflow-clip h-8 cursor-pointer hover:underline ">
                <img
                  className="w-10 h-10 "
                  src={LoadingSpinner}
                  alt="LoadingSpinner"
                />
                <p className="text-xs font-gilroy-regular truncate ">
                  {"Uploading... " + fileName}
                </p>
              </div>
            ) : value && !value?.startsWith("https://") ? (
              <div className="flex items-center gap-1 text-sm font-gilroy-semi-bold overflow-clip h-8 cursor-pointer hover:underline ">
                <CircledTickIcon size="18" color="#D4A15E" />
                <p className="text-xs font-gilroy-regular truncate ">
                  File uploaded successfully!
                </p>
              </div>
            ) : null}
          </div>
        </ToolTip>

        {value &&
          !isLoading &&
          typeof value === "string" &&
          value.startsWith("https") && (
            <Link to={value} target="_blank">
              <div className="flex items-center gap-2 text-sm font-gilroy-semi-bold w-fit  cursor-pointer hover:underline border border-c-white-4 px-2 py-2">
                {/* <FileIcon /> */}
                {extension === "pdf" && <PDFFileIcon />}
                {extension === "jpg" && <JPGIcon />}
                {extension === "jpeg" && <JPEGIcon />}
                {extension === "png" && <PNGIcon />}
                {inputID === "file-upload-gst" && (
                  <p>gst_certificate.{extension}</p>
                )}
                {inputID === "file-upload-cc" && (
                  <p>cancelled_cheque.{extension}</p>
                )}
              </div>
            </Link>
          )}
      </div>

      <p className="text-[10px] font-gilroy-regular mb-2">
        Only pdf, jpeg, jpg, png are supported. (File count : 1 & Max Size :
        20MB)
      </p>

      <ErrorMessage error={error} />
    </div>
  );
};

export default UploadFile;
