import React from "react";

import { LegendData } from "../../../utils/data";
import Legend from "./Legend";

const LegendList = () => {
  return (
    <div className="flex flex-wrap gap-4 lg:gap-6 2xl:gap-[50px]">
      {LegendData?.map((legend) => {
        return (
          <Legend
            key={legend?.id}
            colour={legend?.colour}
            label={legend?.label}
          />
        );
      })}
    </div>
  );
};

export default LegendList;
