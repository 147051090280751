import { useEffect, useRef, useState } from "react";
import useOnClickOutside from "../hooks/useClickOutside";
import CrossIcon from "../assets/CrossIcon";
import SearchIcon from "../assets/SearchIcon";
import InputField from "./InputField";
import ErrorMessage from "./ErrorMessage";

type Option = {
  value?: string;
  label?: any;
};

type SelectFieldProps = {
  label?: string;
  options: Option[];
  leftText?: boolean;
  value: string | undefined;
  onChange: (value: string) => void;
  placeholder?: string;
  smallText?: boolean;
  disabled?: boolean;
  height?: string;
  width?: string;
  downIcon?: boolean;
  searchPlaceHolder?: string;
  hoverText?: string;
  valueFont?: string;
  hint?: string | string[];
  totalPage?: number;
  page?: number;
  setPage?: any;
  Xsearch?: any;
  setXSearch?: any;
  onClear?: any;
  required?: boolean;
  setProfessionalKind?: any;
};

const disabledStyle = "cursor-not-allowed border-[#F4F2EE] text-[#b4b4b4]";

const ProfessionalDropdown = ({
  options,
  value,
  label,
  leftText = false,
  onChange,
  disabled = false,
  height = "",
  width,
  searchPlaceHolder = "",
  valueFont = "text-[14px] font-gilroy-regular",
  totalPage,
  page,
  setPage,
  hint,
  Xsearch,
  setXSearch,
  onClear,
  required,
  placeholder,
  setProfessionalKind,
}: SelectFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const listRef = useRef(null);
  useOnClickOutside(selectRef, () => setIsOpen(false));

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option): void => {
    setIsOpen(false);
    const selectedValue = option.value || "";
    onChange(selectedValue);
    if (setProfessionalKind) {
      setProfessionalKind(option?.label?.kind);
    }
  };

  //   useEffect(() => {
  //     const handleScroll = () => {
  //       if (listRef?.current) {
  //         const { scrollTop, scrollHeight, clientHeight }: any = listRef.current;

  //         if (
  //           totalPage !== undefined &&
  //           page &&
  //           page < totalPage &&
  //           scrollTop + clientHeight >= scrollHeight - 200
  //         ) {
  //           setPage(page + 1);
  //         }
  //       }
  //     };

  //     const list: any = listRef.current;

  //     if (list) {
  //       list.addEventListener("scroll", handleScroll);
  //     }

  //     return () => {
  //       if (list) {
  //         list.removeEventListener("scroll", handleScroll);
  //       }
  //     };
  //   });
  useEffect(() => {
    const handleScroll = () => {
      if (listRef?.current) {
        const { scrollTop, scrollHeight, clientHeight }: any = listRef.current;

        if (
          totalPage !== undefined &&
          page &&
          page < totalPage &&
          scrollTop + clientHeight >= scrollHeight - 200
        ) {
          setPage(page + 1);
        }
      }
    };

    const list: any = listRef.current;

    if (list) {
      list.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (list) {
        list.removeEventListener("scroll", handleScroll);
      }
    };
  }, [listRef.current]);

  return (
    <div
      ref={selectRef}
      className={`  relative   ${disabled && disabledStyle}`}
    >
      {label && (
        <p
          className={` ${
            leftText && "text-left"
          } font-medium text-[13px] pb-2 `}
        >
          {label}
          {required && <span className="text-red-500 text-xs ml-1">*</span>}
        </p>
      )}
      <div
        onClick={() => {
          !disabled && handleSelectClick();
        }}
        className={`w-full flex justify-between items-center bg-white  cursor-pointer ${
          disabled ? "opacity-30 cursor-not-allowed" : "opacity-100 "
        }`}
      >
        <span
          className={`block truncate ${width} ${valueFont} py-[2.5px] w-full`}
        >
          {value !== "" ? (
            <p>
              {(() => {
                const selectedOption: any = options?.find((el: any) => {
                  return (
                    el?.label?.p_company_id == value ||
                    el?.label?.p_individual_id == value
                  );
                });
                if (selectedOption) {
                  return (
                    <div
                      className={`w-full flex justify-between items-center px-2 py-[0.4vw] bg-white cursor-pointer p-2 border-2 ${
                        !disabled &&
                        "border-[#DEDEDE] hover:border-2 hover:border-[#D4A15E] hover:border-opacity-50 outline-none focus:bg-white focus:border-pot-yellow"
                      } ${height}`}
                    >
                      <div className="flex items-center gap-4  w-full ">
                        <img
                          src={`${process.env.REACT_APP_BUCKET}/${selectedOption.label.profile_photo}`} //eslint-disable-line
                          alt="user"
                          className="h-10 w-10 md:w-5 md:h-5 object-cover"
                        />
                        <div className="flex flex-col md:flex-row md:items-center text-xs gap-x-1 text-left">
                          {selectedOption.label.name ? (
                            <p className="font-gilroy-bold text-[11px] md:text-sm truncate text-c-text2">
                              {selectedOption.label.name &&
                                (selectedOption.label.name.length > 16
                                  ? selectedOption.label.name.slice(0, 16) +
                                    "..."
                                  : selectedOption.label.name)}
                            </p>
                          ) : (
                            <p>-</p>
                          )}
                          <div className="hidden md:block w-[4px] h-[4px] rounded-full bg-pot-yellow"></div>
                          <p className="text-[11px] md:text-sm text-c-text2 ">
                            {selectedOption.label.category_name?.toUpperCase()[0] +
                              selectedOption.label.category_name
                                ?.slice(1)
                                ?.toLowerCase()}
                          </p>
                          <div className="hidden md:block  w-[4px] h-[4px] rounded-full bg-pot-yellow"></div>
                          <p className="text-[11px] md:text-sm">
                            {selectedOption.label.practice_location_city}
                          </p>
                        </div>
                      </div>
                      <div className="w-[20px] flex justify-center items-center">
                        <button
                          onClick={() => {
                            if (disabled) return;
                            onClear();
                          }}
                          className="stroke-black"
                        >
                          <CrossIcon />
                        </button>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="w-full ">
                      <InputField
                        leftIcon={<SearchIcon />}
                        placeholder={searchPlaceHolder}
                        value={Xsearch}
                        onChange={(e: any) => {
                          setXSearch(e);
                        }}
                      />
                    </div>
                  );
                }
              })()}
            </p>
          ) : (
            searchPlaceHolder !== "" && (
              <div className="w-full ">
                <InputField
                  leftIcon={<SearchIcon />}
                  placeholder={searchPlaceHolder}
                  value={Xsearch}
                  onChange={(e: any) => {
                    setXSearch(e);
                  }}
                />
              </div>
            )
          )}
        </span>
      </div>
      {isOpen && (
        <div
          ref={listRef}
          className=" w-full bg-white  shadow-md mt-1 z-30 text-start h-auto max-h-[200px] overflow-auto custom-scrollbar absolute top-[100%] text-[80%]"
        >
          <ul className={`h-auto overflow-auto  z-50`}>
            {options?.map((option: any, i) => {
              return (
                <li
                  key={i}
                  onClick={() => handleOptionClick(option)}
                  className={`px-4 py-2 cursor-pointer hover:bg-gray-100 font-gilroy-regular text-[14px] leading-5 ${
                    option?.value === value ? "bg-gray-100" : ""
                  }`}
                >
                  <div className="flex justify-between items-center pr-4 border-2 border-pot-yellow/60 bg-white text-c-text2">
                    <div className="flex items-center gap-x-2 py-1.5 md:py-2 px-1.5 md:px-3  w-full">
                      {option?.label?.profile_photo ? (
                        <img
                          src={`${process.env.REACT_APP_BUCKET}/${option?.label?.profile_photo}`} //eslint-disable-line
                          alt="user"
                          className="h-10 w-10 md:w-5 md:h-5 object-cover"
                        />
                      ) : (
                        <img
                          src={``}
                          alt="user"
                          className="h-10 w-10 md:w-5 md:h-5 object-cover"
                        />
                      )}

                      <div className="flex flex-col  md:flex-row md:items-center text-xs gap-x-1 text-left">
                        {option?.label?.name ? (
                          <p className="font-gilroy-bold text-[11px] md:text-sm truncate text-c-text2">
                            {option?.label?.name &&
                              (option.label.name.length > 15
                                ? option.label.name.slice(0, 15) + "..."
                                : option.label.name)}
                          </p>
                        ) : (
                          <p>-</p>
                        )}

                        <div className="hidden md:block w-[4px] h-[4px] rounded-full bg-pot-yellow"></div>

                        <p className="text-[11px] md:text-sm text-c-text2">
                          {option?.label?.category_name?.toUpperCase()[0] +
                            option?.label?.category_name
                              ?.slice(1)
                              .toLowerCase()}
                        </p>
                        <div className="hidden md:block  w-[4px] h-[4px] rounded-full bg-pot-yellow"></div>
                        <p className="text-[11px] md:text-sm">
                          {option?.label?.practice_location_city}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
            {options?.length === 0 && (
              <li
                className={`px-4 py-2 font-gilroy-regular text[14px] leading-5 `}
              >
                No option available
              </li>
            )}
          </ul>
        </div>
      )}
      <ErrorMessage error={hint} />
    </div>
  );
};

export default ProfessionalDropdown;
