import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BottomBar from "./QuotationDetailsComponent/BottomBar";
import AddProductModal from "./QuotationDetailsComponent/AddProductModal";
import DefaultQuotationTable from "./QuotationDetailsComponent/DefaultQuotationTable";
import LegendList from "./components/LegenList";

const defaultCartDummyData = {
  data: {
    floors: [
      {
        id: null,
        name: null,
        sequence: null,
        areas: [
          {
            id: null,
            name: null,
            sequence: null,
            items: [
              {
                id: "0c7ac92a-16d0-4da2-b82f-7a4911d1dece",
                product: {
                  id: "41ae1a66-ca66-41d4-8167-183c0f013252",
                  name: "10mm x 15mm Surface Empty Profile (Copy)",
                  code: "HLL - 1001",
                  primary_image:
                    "products/776c732c-bfc1-490c-b907-43b7dcc82513.webp",
                  brand: {
                    name: "Hybec",
                    id: "1576f26a-a037-4f2e-b71e-7bcbd03d4ba1",
                  },
                  fixed_details: {
                    "IP RATED": "IP20",
                    Dimension: "10mm x 15mm",
                    "Max Length": "2 Mtr.",
                    "Body Colour": "Aluminium finish",
                    "Housing Material": "Aluminium + Acrylic",
                  },
                },
                cart_properties: {
                  code: "HLL - 1001",
                  wattage: null,
                  lighting_control: null,
                  lighting_control_category: null,
                  track_shape: "Line Without Driver and LED Strip",
                  track_dimension: "65.6168ft x 65.6168ft x 65.6168ft",
                  track_driver_quantity: 0,
                  light_color: null,
                  body_color: "Aluminium finish",
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 1,
                part_list: [],
                price_unit: 120.02,
                price_total: 120.02,
              },
              {
                id: "751db866-d6b4-4a30-8029-c3c14fc3d35b",
                product: {
                  id: "92194951-e194-45a7-a11d-03d1f781d813",
                  name: "Surface Trackline",
                  code: null,
                  primary_image:
                    "products/cdb6c6b1-6802-419f-a853-6cadd29035e8.webp",
                  brand: {
                    name: "Hybec",
                    id: "1576f26a-a037-4f2e-b71e-7bcbd03d4ba1",
                  },
                  fixed_details: {
                    "Housing Material ": "Aluminium",
                  },
                },
                cart_properties: {
                  code: "TR - 4151",
                  wattage: null,
                  lighting_control: null,
                  lighting_control_category: null,
                  track_shape: "L Shape - Surface Trackline",
                  track_dimension: "12ft x 15ft",
                  track_driver_quantity: 0,
                  light_color: null,
                  body_color: "Black",
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 2,
                part_list: [],
                price_unit: 8370.0,
                price_total: 8370.0,
              },
              {
                id: "2108dcb2-9850-49fe-9a2a-70463629e4e9",
                product: {
                  id: "19fbe15b-7f6b-4d79-8170-09a19fb6d4eb",
                  name: "High Power LED Spot",
                  code: null,
                  primary_image:
                    "products/342621cc-ecd6-47b8-9281-7fbbf29d4c0f.webp",
                  brand: {
                    name: "Hybec",
                    id: "1576f26a-a037-4f2e-b71e-7bcbd03d4ba1",
                  },
                  fixed_details: {
                    "Lumens/W": "80Lm/W",
                    "Beam Angle": "40\u00b0",
                    "Body Colour": "White",
                    "Light Source": "BRIDGELUX LED",
                    "Input Voltage": "100-240V",
                    "Housing Material ": "Aluminium",
                    "LED Constant Current": "40V",
                  },
                },
                cart_properties: {
                  code: "EB-9327",
                  wattage: "30W Spot",
                  lighting_control: null,
                  lighting_control_category: null,
                  track_shape: null,
                  track_dimension: null,
                  track_driver_quantity: null,
                  light_color: "6000k",
                  body_color: "White",
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 3,
                part_list: [],
                price_unit: 2870.0,
                price_total: 2870.0,
              },
              {
                id: "4fe62fdd-1caa-4962-b508-33e4cc90b7c0",
                product: {
                  id: "28cc18c1-3f28-4346-a793-29e57ddab506",
                  name: "ESAW - LED Surface Adjustable Spotlight",
                  code: null,
                  primary_image:
                    "products/26403b81-1c07-41d3-8e6c-0f72cf5b45d4.webp",
                  brand: {
                    name: "Hybec",
                    id: "1576f26a-a037-4f2e-b71e-7bcbd03d4ba1",
                  },
                  fixed_details: {
                    Height: "187mm",
                    "IP RATED": "20",
                    " Dimension": "\u00d855x75mm",
                    "Beam Angle": "24\u00b0",
                    "Light Source": "BRIDGELUX",
                    "Output Wattage": "10w",
                    "Housing Material ": "Aluminium",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: null,
                  lighting_control: null,
                  lighting_control_category: null,
                  track_shape: null,
                  track_dimension: null,
                  track_driver_quantity: null,
                  light_color: "3000k",
                  body_color: "Black",
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 4,
                part_list: [],
                price_unit: 1800.0,
                price_total: 1800.0,
              },
              {
                id: "48b86e19-8218-4f59-8785-8495f91267b5",
                product: {
                  id: "92194951-e194-45a7-a11d-03d1f781d813",
                  name: "Surface Trackline",
                  code: null,
                  primary_image:
                    "products/cdb6c6b1-6802-419f-a853-6cadd29035e8.webp",
                  brand: {
                    name: "Hybec",
                    id: "1576f26a-a037-4f2e-b71e-7bcbd03d4ba1",
                  },
                  fixed_details: {
                    "Housing Material ": "Aluminium",
                  },
                },
                cart_properties: {
                  code: "TR - 4151",
                  wattage: null,
                  lighting_control: null,
                  lighting_control_category: null,
                  track_shape: "Straight Line - Surface Trackline",
                  track_dimension: "20ft",
                  track_driver_quantity: 0,
                  light_color: null,
                  body_color: "White",
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 5,
                part_list: [],
                price_unit: 5670.0,
                price_total: 5670.0,
              },
              {
                id: "1ad9690b-3920-4572-9a1e-ed5210491b81",
                product: {
                  id: "4_Wire_Magnetic_Track",
                  name: "4-Wire Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/4_Wire_Magnetic_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Body Color": "Black",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 500,
                  lighting_control: "DALI",
                  lighting_control_category: "DALI_TUNABLE",
                  track_shape: "line",
                  track_dimension: "10m",
                  track_driver_quantity: 3,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 3,
                floor: null,
                area: null,
                sequence: 6,
                part_list: [
                  {
                    id: "surface_4_magnetic_track_2m",
                    product: {
                      id: "surface_4_magnetic_track_2m",
                      name: "Surface Magnetic Track DALI - 4 Wire",
                      code: "PRO-832",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_832.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-832",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 5,
                    price_unit: 14020,
                    price_total: 70100,
                  },
                  {
                    id: "track_spotlight_pro881",
                    product: {
                      id: "track_spotlight_pro881",
                      name: "8w",
                      code: "PRO-881",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_881.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-881",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 8340,
                    price_total: 8340,
                  },
                  {
                    id: "48v_non_dimmable_driver_200w",
                    product: {
                      id: "48v_non_dimmable_driver_200w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-200W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(200W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-200W",
                      wattage: 200,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 2,
                    price_unit: 2880,
                    price_total: 5760,
                  },
                  {
                    id: "48v_non_dimmable_driver_100w",
                    product: {
                      id: "48v_non_dimmable_driver_100w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-100W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(100W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-100W",
                      wattage: 100,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 1920,
                    price_total: 1920,
                  },
                ],
                price_unit: 86120,
                price_total: 258360,
              },
              {
                id: "440c002d-c8fd-482f-b7dc-ea9707c10ddc",
                product: {
                  id: "4_Wire_Magnetic_Track",
                  name: "4-Wire Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/4_Wire_Magnetic_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Body Color": "Black",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 400,
                  lighting_control: "DALI",
                  lighting_control_category: "DALI_TUNABLE",
                  track_shape: "line",
                  track_dimension: "8m",
                  track_driver_quantity: 2,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 7,
                part_list: [
                  {
                    id: "surface_4_magnetic_track_2m",
                    product: {
                      id: "surface_4_magnetic_track_2m",
                      name: "Surface Magnetic Track DALI - 4 Wire",
                      code: "PRO-832",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_832.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-832",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 4,
                    price_unit: 14020,
                    price_total: 56080,
                  },
                  {
                    id: "48v_non_dimmable_driver_200w",
                    product: {
                      id: "48v_non_dimmable_driver_200w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-200W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(200W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-200W",
                      wattage: 200,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 2,
                    price_unit: 2880,
                    price_total: 5760,
                  },
                ],
                price_unit: 61840,
                price_total: 61840,
              },
              {
                id: "dc29339b-f40f-47cb-8f37-7ef8cbd5cc93",
                product: {
                  id: "4_Wire_Magnetic_Track",
                  name: "4-Wire Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/4_Wire_Magnetic_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Body Color": "Black",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 800,
                  lighting_control: "DALI",
                  lighting_control_category: "DALI_TUNABLE",
                  track_shape: "line",
                  track_dimension: "15m",
                  track_driver_quantity: 5,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 2,
                floor: null,
                area: null,
                sequence: 8,
                part_list: [
                  {
                    id: "recessed_4_magnetic_track_2m",
                    product: {
                      id: "recessed_4_magnetic_track_2m",
                      name: "Recessed magnetic track DALI - 4 Wire",
                      code: "PRO-803",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_803.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-803",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 7,
                    price_unit: 15460,
                    price_total: 108220,
                  },
                  {
                    id: "recessed_4_magnetic_track_1m",
                    product: {
                      id: "recessed_4_magnetic_track_1m",
                      name: "Recessed magnetic track DALI - 4 Wire",
                      code: "PRO-801",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_801.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-801",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 9000,
                    price_total: 9000,
                  },
                  {
                    id: "48v_non_dimmable_driver_200w",
                    product: {
                      id: "48v_non_dimmable_driver_200w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-200W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(200W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-200W",
                      wattage: 200,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 3,
                    price_unit: 2880,
                    price_total: 8640,
                  },
                  {
                    id: "48v_non_dimmable_driver_100w",
                    product: {
                      id: "48v_non_dimmable_driver_100w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-100W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(100W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-100W",
                      wattage: 100,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 2,
                    price_unit: 1920,
                    price_total: 3840,
                  },
                ],
                price_unit: 129700,
                price_total: 259400,
              },
              {
                id: "0eb38b0a-7f4b-4da6-baaa-235e0eb7b6ac",
                product: {
                  id: "Brass_Magnetic_Track",
                  name: "Brass Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/Brass_Magnetic_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Housing Material": "Brass",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 100,
                  lighting_control: null,
                  lighting_control_category: null,
                  track_shape: "circle",
                  track_dimension: "",
                  track_driver_quantity: 1,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 9,
                part_list: [
                  {
                    id: "round_track_g_776_d880mm_200mm",
                    product: {
                      id: "round_track_g_776_d880mm_200mm",
                      name: "Round Track",
                      code: "G 776",
                      primary_image:
                        "track_product_images/Brass_Magnetic_Track/profile_main_images/G_776_2.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "G 776",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 56700,
                    price_total: 56700,
                  },
                  {
                    id: "track_spotlight_pro701",
                    product: {
                      id: "track_spotlight_pro701",
                      name: "4w",
                      code: "PRO-701",
                      primary_image:
                        "track_product_images/Brass_Magnetic_Track/fixtures_main_image/PRO_701.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-701",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: "3K",
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 10200,
                    price_total: 10200,
                  },
                  {
                    id: "track_spotlight_pro702",
                    product: {
                      id: "track_spotlight_pro702",
                      name: "9w",
                      code: "PRO-702",
                      primary_image:
                        "track_product_images/Brass_Magnetic_Track/fixtures_main_image/PRO_702.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-702",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 12600,
                    price_total: 12600,
                  },
                  {
                    id: "42v_hld_driver_100w",
                    product: {
                      id: "42v_hld_driver_100w",
                      name: "HLD-4873 (100W-42V)",
                      code: "HLD-4873",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(100W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "HLD-4873",
                      wattage: 100,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 5760,
                    price_total: 5760,
                  },
                ],
                price_unit: 85260,
                price_total: 85260,
              },
              {
                id: "fd069230-2258-4db7-ba35-ea5fad849cf0",
                product: {
                  id: "4_Wire_Magnetic_Track",
                  name: "4-Wire Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/4_Wire_Magnetic_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Body Color": "Black",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 1500,
                  lighting_control: "DALI",
                  lighting_control_category: "DALI_TUNABLE",
                  track_shape: "l_shape",
                  track_dimension: "15m x 15m",
                  track_driver_quantity: 8,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 10,
                part_list: [
                  {
                    id: "suspended_4_magnetic_track_1m",
                    product: {
                      id: "suspended_4_magnetic_track_1m",
                      name: "Suspended Magnetic Track DALI - 4 Wire",
                      code: "PRO-831-PRO-615",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_831_PRO_615.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-831-PRO-615",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 2,
                    price_unit: 9600,
                    price_total: 19200,
                  },
                  {
                    id: "suspended_4_magnetic_track_2m",
                    product: {
                      id: "suspended_4_magnetic_track_2m",
                      name: "Suspended Magnetic Track DALI - 4 Wire",
                      code: "PRO-832-PRO-615",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_832_PRO_615.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-832-PRO-615",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 14,
                    price_unit: 16420,
                    price_total: 229880,
                  },
                  {
                    id: "surface_track_connector_pro_836",
                    product: {
                      id: "surface_track_connector_pro_836",
                      name: "Surface Track Connectors",
                      code: "PRO-836",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/connector_images/main_images/PRO_836.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-836",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 1140,
                    price_total: 1140,
                  },
                  {
                    id: "track_l_diffuser_pro855",
                    product: {
                      id: "track_l_diffuser_pro855",
                      name: "10w",
                      code: "PRO-855",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_855.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-855",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 17040,
                    price_total: 17040,
                  },
                  {
                    id: "48v_non_dimmable_driver_200w",
                    product: {
                      id: "48v_non_dimmable_driver_200w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-200W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(200W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-200W",
                      wattage: 200,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 7,
                    price_unit: 2880,
                    price_total: 20160,
                  },
                  {
                    id: "48v_non_dimmable_driver_100w",
                    product: {
                      id: "48v_non_dimmable_driver_100w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-100W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(100W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-100W",
                      wattage: 100,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 1920,
                    price_total: 1920,
                  },
                ],
                price_unit: 289340,
                price_total: 289340,
              },
              {
                id: "ab1c3506-9887-4b88-8a72-0f76388be5fd",
                product: {
                  id: "4_Wire_Magnetic_Track",
                  name: "4-Wire Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/4_Wire_Magnetic_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Body Color": "Black",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 3000,
                  lighting_control: "DALI",
                  lighting_control_category: "DALI_TUNABLE",
                  track_shape: "rectangle",
                  track_dimension: "15m x 15m",
                  track_driver_quantity: 15,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 4,
                floor: null,
                area: null,
                sequence: 11,
                part_list: [
                  {
                    id: "surface_track_connector_pro_836",
                    product: {
                      id: "surface_track_connector_pro_836",
                      name: "Surface Track Connectors",
                      code: "PRO-836",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/connector_images/main_images/PRO_836.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-836",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 4,
                    price_unit: 1140,
                    price_total: 4560,
                  },
                  {
                    id: "suspended_4_magnetic_track_1m",
                    product: {
                      id: "suspended_4_magnetic_track_1m",
                      name: "Suspended Magnetic Track DALI - 4 Wire",
                      code: "PRO-831-PRO-615",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_831_PRO_615.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-831-PRO-615",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 4,
                    price_unit: 9600,
                    price_total: 38400,
                  },
                  {
                    id: "suspended_4_magnetic_track_2m",
                    product: {
                      id: "suspended_4_magnetic_track_2m",
                      name: "Suspended Magnetic Track DALI - 4 Wire",
                      code: "PRO-832-PRO-615",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_832_PRO_615.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-832-PRO-615",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 28,
                    price_unit: 16420,
                    price_total: 459760,
                  },
                  {
                    id: "track_l_diffuser_pro855",
                    product: {
                      id: "track_l_diffuser_pro855",
                      name: "10w",
                      code: "PRO-855",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_855.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-855",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: "Tunable",
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 17040,
                    price_total: 17040,
                  },
                  {
                    id: "track_spotlight_pro881",
                    product: {
                      id: "track_spotlight_pro881",
                      name: "8w",
                      code: "PRO-881",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_881.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-881",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: "3K",
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 8340,
                    price_total: 8340,
                  },
                  {
                    id: "track_spotlight_pro882",
                    product: {
                      id: "track_spotlight_pro882",
                      name: "14w",
                      code: "PRO-882",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_882.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-882",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: "4K",
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 10680,
                    price_total: 10680,
                  },
                  {
                    id: "track_spotlight_pro861",
                    product: {
                      id: "track_spotlight_pro861",
                      name: "13w",
                      code: "PRO-861",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_861.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-861",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: "Tunable",
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 11640,
                    price_total: 11640,
                  },
                  {
                    id: "48v_non_dimmable_driver_200w",
                    product: {
                      id: "48v_non_dimmable_driver_200w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-200W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(200W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-200W",
                      wattage: 200,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 15,
                    price_unit: 2880,
                    price_total: 43200,
                  },
                ],
                price_unit: 593620,
                price_total: 2374480,
              },
              {
                id: "41e6635a-cd6a-46e1-9208-a74a67e5ddbd",
                product: {
                  id: "Brass_Magnetic_Track",
                  name: "Brass Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/Brass_Magnetic_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Housing Material": "Brass",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 100,
                  lighting_control: null,
                  lighting_control_category: null,
                  track_shape: "circle",
                  track_dimension: "",
                  track_driver_quantity: 1,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 2,
                floor: null,
                area: null,
                sequence: 12,
                part_list: [
                  {
                    id: "round_track_g_776_d880mm_200mm",
                    product: {
                      id: "round_track_g_776_d880mm_200mm",
                      name: "Round Track",
                      code: "G 776",
                      primary_image:
                        "track_product_images/Brass_Magnetic_Track/profile_main_images/G_776_2.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "G 776",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 56700,
                    price_total: 56700,
                  },
                  {
                    id: "track_spotlight_pro701",
                    product: {
                      id: "track_spotlight_pro701",
                      name: "4w",
                      code: "PRO-701",
                      primary_image:
                        "track_product_images/Brass_Magnetic_Track/fixtures_main_image/PRO_701.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-701",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 10200,
                    price_total: 10200,
                  },
                  {
                    id: "track_spotlight_pro702",
                    product: {
                      id: "track_spotlight_pro702",
                      name: "9w",
                      code: "PRO-702",
                      primary_image:
                        "track_product_images/Brass_Magnetic_Track/fixtures_main_image/PRO_702.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-702",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 12600,
                    price_total: 12600,
                  },
                  {
                    id: "42v_hld_driver_100w",
                    product: {
                      id: "42v_hld_driver_100w",
                      name: "HLD-4873 (100W-42V)",
                      code: "HLD-4873",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(100W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "HLD-4873",
                      wattage: 100,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 5760,
                    price_total: 5760,
                  },
                ],
                price_unit: 85260,
                price_total: 170520,
              },
              {
                id: "d622d1b3-c7a5-41c3-a3dc-79bf1bfcbffe",
                product: {
                  id: "4_Wire_Magnetic_Track",
                  name: "4-Wire Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/4_Wire_Magnetic_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Body Color": "Black",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 2100,
                  lighting_control: "DALI",
                  lighting_control_category: "DALI_TUNABLE",
                  track_shape: "c_shape",
                  track_dimension: "15m x 12m x 15m",
                  track_driver_quantity: 11,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 13,
                part_list: [
                  {
                    id: "suspended_4_magnetic_track_1m",
                    product: {
                      id: "suspended_4_magnetic_track_1m",
                      name: "Suspended Magnetic Track DALI - 4 Wire",
                      code: "PRO-831-PRO-615",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_831_PRO_615.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-831-PRO-615",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 2,
                    price_unit: 9600,
                    price_total: 19200,
                  },
                  {
                    id: "suspended_4_magnetic_track_2m",
                    product: {
                      id: "suspended_4_magnetic_track_2m",
                      name: "Suspended Magnetic Track DALI - 4 Wire",
                      code: "PRO-832-PRO-615",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_832_PRO_615.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-832-PRO-615",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 20,
                    price_unit: 16420,
                    price_total: 328400,
                  },
                  {
                    id: "surface_track_connector_pro_836",
                    product: {
                      id: "surface_track_connector_pro_836",
                      name: "Surface Track Connectors",
                      code: "PRO-836",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/connector_images/main_images/PRO_836.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-836",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 2,
                    price_unit: 1140,
                    price_total: 2280,
                  },
                  {
                    id: "track_diffuser_pro841",
                    product: {
                      id: "track_diffuser_pro841",
                      name: "6w",
                      code: "PRO-841",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_841.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-841",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 9120,
                    price_total: 9120,
                  },
                  {
                    id: "track_spotlight_pro861",
                    product: {
                      id: "track_spotlight_pro861",
                      name: "13w",
                      code: "PRO-861",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_861.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-861",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: "Tunable",
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 11640,
                    price_total: 11640,
                  },
                  {
                    id: "track_spotlight_pro882",
                    product: {
                      id: "track_spotlight_pro882",
                      name: "14w",
                      code: "PRO-882",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_882.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-882",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: "3K",
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 10680,
                    price_total: 10680,
                  },
                  {
                    id: "track_l_diffuser_pro855",
                    product: {
                      id: "track_l_diffuser_pro855",
                      name: "10w",
                      code: "PRO-855",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_855.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-855",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: "Tunable",
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 2,
                    price_unit: 17040,
                    price_total: 34080,
                  },
                  {
                    id: "track_spotlight_pro882",
                    product: {
                      id: "track_spotlight_pro882",
                      name: "14w",
                      code: "PRO-882",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_882.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-882",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: "6K",
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 10680,
                    price_total: 10680,
                  },
                  {
                    id: "48v_non_dimmable_driver_200w",
                    product: {
                      id: "48v_non_dimmable_driver_200w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-200W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(200W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-200W",
                      wattage: 200,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 10,
                    price_unit: 2880,
                    price_total: 28800,
                  },
                  {
                    id: "48v_non_dimmable_driver_100w",
                    product: {
                      id: "48v_non_dimmable_driver_100w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-100W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(100W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-100W",
                      wattage: 100,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 1920,
                    price_total: 1920,
                  },
                ],
                price_unit: 456800,
                price_total: 456800,
              },
              {
                id: "7ce4d767-b839-484c-8c49-2e2a06e6542b",
                product: {
                  id: "4_Wire_Magnetic_Track",
                  name: "4-Wire Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/4_Wire_Magnetic_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Body Color": "Black",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 2400,
                  lighting_control: "DALI",
                  lighting_control_category: "DALI_TUNABLE",
                  track_shape: "rectangle",
                  track_dimension: "12m x 12m",
                  track_driver_quantity: 12,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 14,
                part_list: [
                  {
                    id: "surface_track_connector_pro_836",
                    product: {
                      id: "surface_track_connector_pro_836",
                      name: "Surface Track Connectors",
                      code: "PRO-836",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/connector_images/main_images/PRO_836.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-836",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 4,
                    price_unit: 1140,
                    price_total: 4560,
                  },
                  {
                    id: "suspended_4_magnetic_track_2m",
                    product: {
                      id: "suspended_4_magnetic_track_2m",
                      name: "Suspended Magnetic Track DALI - 4 Wire",
                      code: "PRO-832-PRO-615",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_832_PRO_615.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-832-PRO-615",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 24,
                    price_unit: 16420,
                    price_total: 394080,
                  },
                  {
                    id: "48v_non_dimmable_driver_200w",
                    product: {
                      id: "48v_non_dimmable_driver_200w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-200W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(200W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-200W",
                      wattage: 200,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 12,
                    price_unit: 2880,
                    price_total: 34560,
                  },
                ],
                price_unit: 433200,
                price_total: 433200,
              },
              {
                id: "9a7bc377-2c35-4c14-8f01-d3ebb38bb852",
                product: {
                  id: "Stringline_Plus_System_Track",
                  name: "Stringline Plus System Track",
                  code: null,
                  primary_image:
                    "track_product_images/Stringline_Plus_System_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Body Color": "Black",
                    "Housing Material": "Aluminium",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 100,
                  lighting_control: null,
                  lighting_control_category: null,
                  track_shape: "line",
                  track_dimension: "8m",
                  track_driver_quantity: 1,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 15,
                part_list: [
                  {
                    id: "stringline_pro_950_8",
                    product: {
                      id: "stringline_pro_950_8",
                      name: "Stringline",
                      code: "PRO-950",
                      primary_image:
                        "track_product_images/Stringline_Plus_System_Track/profile_main_image/PRO_950.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-950",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 15500,
                    price_total: 15500,
                  },
                  {
                    id: "48v_non_dimmable_driver_100w",
                    product: {
                      id: "48v_non_dimmable_driver_100w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-100W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(100W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-100W",
                      wattage: 100,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 1920,
                    price_total: 1920,
                  },
                ],
                price_unit: 17420,
                price_total: 17420,
              },
              {
                id: "06447b5a-84c5-4f5e-b70b-8ed14417cc67",
                product: {
                  id: "Stringline_Plus_System_Track",
                  name: "Stringline Plus System Track",
                  code: null,
                  primary_image:
                    "track_product_images/Stringline_Plus_System_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Body Color": "Black",
                    "Housing Material": "Aluminium",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 200,
                  lighting_control: null,
                  lighting_control_category: null,
                  track_shape: "line",
                  track_dimension: "8m",
                  track_driver_quantity: 1,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 16,
                part_list: [
                  {
                    id: "stringline_pro_950_8",
                    product: {
                      id: "stringline_pro_950_8",
                      name: "Stringline",
                      code: "PRO-950",
                      primary_image:
                        "track_product_images/Stringline_Plus_System_Track/profile_main_image/PRO_950.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-950",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 15500,
                    price_total: 15500,
                  },
                  {
                    id: "48v_non_dimmable_driver_200w",
                    product: {
                      id: "48v_non_dimmable_driver_200w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-200W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(200W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-200W",
                      wattage: 200,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 2880,
                    price_total: 2880,
                  },
                ],
                price_unit: 18380,
                price_total: 18380,
              },
              {
                id: "12e27122-38a0-4c5f-b2ce-0d7623fc1234",
                product: {
                  id: "Brass_Magnetic_Track",
                  name: "Brass Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/Brass_Magnetic_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Housing Material": "Brass",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 100,
                  lighting_control: null,
                  lighting_control_category: null,
                  track_shape: "circle",
                  track_dimension: "1500mm",
                  track_driver_quantity: 1,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 17,
                part_list: [
                  {
                    id: "round_track_g_778_d1500mm_200mm",
                    product: {
                      id: "round_track_g_778_d1500mm_200mm",
                      name: "Round Track",
                      code: "G 778",
                      primary_image:
                        "track_product_images/Brass_Magnetic_Track/profile_main_images/G_778_2.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "G 778",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 90100,
                    price_total: 90100,
                  },
                  {
                    id: "live_connector_g762",
                    product: {
                      id: "live_connector_g762",
                      name: "Live Connector",
                      code: "G-762",
                      primary_image:
                        "track_product_images/Brass_Magnetic_Track/connector_images/main_images/G_762.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "G-762",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 5400,
                    price_total: 5400,
                  },
                  {
                    id: "42v_hld_driver_100w",
                    product: {
                      id: "42v_hld_driver_100w",
                      name: "HLD-4873 (100W-42V)",
                      code: "HLD-4873",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(100W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "HLD-4873",
                      wattage: 100,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 5760,
                    price_total: 5760,
                  },
                ],
                price_unit: 95860,
                price_total: 95860,
              },
              {
                id: "7f256d87-84f0-4784-9840-01c54f2848df",
                product: {
                  id: "4_Wire_Magnetic_Track",
                  name: "4-Wire Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/4_Wire_Magnetic_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Body Color": "Black",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 800,
                  lighting_control: "DALI",
                  lighting_control_category: "DALI_TUNABLE",
                  track_shape: "line",
                  track_dimension: "15m",
                  track_driver_quantity: 5,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 18,
                part_list: [
                  {
                    id: "suspended_4_magnetic_track_2m",
                    product: {
                      id: "suspended_4_magnetic_track_2m",
                      name: "Suspended Magnetic Track DALI - 4 Wire",
                      code: "PRO-832-PRO-615",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_832_PRO_615.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-832-PRO-615",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 7,
                    price_unit: 16420,
                    price_total: 114940,
                  },
                  {
                    id: "suspended_4_magnetic_track_1m",
                    product: {
                      id: "suspended_4_magnetic_track_1m",
                      name: "Suspended Magnetic Track DALI - 4 Wire",
                      code: "PRO-831-PRO-615",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_831_PRO_615.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-831-PRO-615",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 9600,
                    price_total: 9600,
                  },
                  {
                    id: "48v_non_dimmable_driver_200w",
                    product: {
                      id: "48v_non_dimmable_driver_200w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-200W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(200W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-200W",
                      wattage: 200,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 3,
                    price_unit: 2880,
                    price_total: 8640,
                  },
                  {
                    id: "48v_non_dimmable_driver_100w",
                    product: {
                      id: "48v_non_dimmable_driver_100w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-100W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(100W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-100W",
                      wattage: 100,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 2,
                    price_unit: 1920,
                    price_total: 3840,
                  },
                ],
                price_unit: 137020,
                price_total: 137020,
              },
              {
                id: "44035a41-cf5a-4cbd-8c8e-266c7eb190b7",
                product: {
                  id: "superslim_Magnetic_Track",
                  name: "SuperSlim Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/superslim_track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Body Color": "Black",
                    "Housing Material": "Aluminium",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 900,
                  lighting_control: "DALI",
                  lighting_control_category: "DALI_TUNABLE",
                  track_shape: "rectangle",
                  track_dimension: "13m x 9m",
                  track_driver_quantity: 5,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 19,
                part_list: [
                  {
                    id: "superslim_surface_track_connector_pro_m45",
                    product: {
                      id: "superslim_surface_track_connector_pro_m45",
                      name: "Superslim Surface Track Connectors",
                      code: "PRO-M45",
                      primary_image:
                        "track_product_images/superslim_track/connector_images/main_images/PRO_M45.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-M45",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 4,
                    price_unit: 1190,
                    price_total: 4760,
                  },
                  {
                    id: "suspended_superslim_magnetic_track_1m",
                    product: {
                      id: "suspended_superslim_magnetic_track_1m",
                      name: "Superslim Suspended Track",
                      code: "PRO-M40-PRO-M55",
                      primary_image:
                        "track_product_images/superslim_track/profile_main_image/PRO_M40_PRO_M55.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-M40-PRO-M55",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 4,
                    price_unit: 9445,
                    price_total: 37780,
                  },
                  {
                    id: "suspended_superslim_magnetic_track_2m",
                    product: {
                      id: "suspended_superslim_magnetic_track_2m",
                      name: "Superslim Suspended Track",
                      code: "PRO-M42-PRO-M55",
                      primary_image:
                        "track_product_images/superslim_track/profile_main_image/PRO_M42_PRO_M55.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-M42-PRO-M55",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 20,
                    price_unit: 15760,
                    price_total: 315200,
                  },
                  {
                    id: "48v_non_dimmable_driver_200w",
                    product: {
                      id: "48v_non_dimmable_driver_200w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-200W",
                      primary_image:
                        "track_product_images/2_Wire_Magnetic_Track/driver_images/PRO_48V_(200W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-200W",
                      wattage: 200,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 4,
                    price_unit: 2880,
                    price_total: 11520,
                  },
                  {
                    id: "48v_non_dimmable_driver_100w",
                    product: {
                      id: "48v_non_dimmable_driver_100w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-100W",
                      primary_image:
                        "track_product_images/2_Wire_Magnetic_Track/driver_images/PRO_48V_(100W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-100W",
                      wattage: 100,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 1920,
                    price_total: 1920,
                  },
                  {
                    id: "superslim_diffuser_pro_m70",
                    product: {
                      id: "superslim_diffuser_pro_m70",
                      name: "12w",
                      code: "PRO-M70",
                      primary_image:
                        "track_product_images/superslim_track/fixtures_main_image/PRO_M70.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-M70",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 7180,
                    price_total: 7180,
                  },
                  {
                    id: "superslim_diffuser_pro_m72",
                    product: {
                      id: "superslim_diffuser_pro_m72",
                      name: "20w",
                      code: "PRO-M72",
                      primary_image:
                        "track_product_images/superslim_track/fixtures_main_image/PRO_M72.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-M72",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: "3K",
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 14040,
                    price_total: 14040,
                  },
                  {
                    id: "superslim_spotlight_pro_m60",
                    product: {
                      id: "superslim_spotlight_pro_m60",
                      name: "3w",
                      code: "PRO-M60",
                      primary_image:
                        "track_product_images/superslim_track/fixtures_main_image/PRO_M60.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-M60",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: "3K",
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 5820,
                    price_total: 5820,
                  },
                  {
                    id: "superslim_diffuser_pro_m72",
                    product: {
                      id: "superslim_diffuser_pro_m72",
                      name: "20w",
                      code: "PRO-M72",
                      primary_image:
                        "track_product_images/superslim_track/fixtures_main_image/PRO_M72.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-M72",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 14040,
                    price_total: 14040,
                  },
                  {
                    id: "superslim_wallwasher_pro_m74",
                    product: {
                      id: "superslim_wallwasher_pro_m74",
                      name: "12w",
                      code: "PRO-M74",
                      primary_image:
                        "track_product_images/superslim_track/fixtures_main_image/PRO_M74.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-M74",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 7800,
                    price_total: 7800,
                  },
                  {
                    id: "superslim_linear_spotlight_pro_m82",
                    product: {
                      id: "superslim_linear_spotlight_pro_m82",
                      name: "8w",
                      code: "PRO-M82",
                      primary_image:
                        "track_product_images/superslim_track/fixtures_main_image/PRO_M82.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-M82",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 9360,
                    price_total: 9360,
                  },
                ],
                price_unit: 429420,
                price_total: 429420,
              },
              {
                id: "f12fe576-d238-483d-bff5-508c80cf49cf",
                product: {
                  id: "4_Wire_Magnetic_Track",
                  name: "4-Wire Magnetic Track",
                  code: null,
                  primary_image:
                    "track_product_images/4_Wire_Magnetic_Track/product_images/product1.webp",
                  brand: null,
                  fixed_details: {
                    "IP Rated": "20",
                    "Body Color": "Black",
                  },
                },
                cart_properties: {
                  code: null,
                  wattage: 600,
                  lighting_control: "DALI",
                  lighting_control_category: "DALI_TUNABLE",
                  track_shape: "line",
                  track_dimension: "11m",
                  track_driver_quantity: 4,
                  light_color: null,
                  body_color: null,
                  reflector_color: null,
                },
                quantity: 1,
                floor: null,
                area: null,
                sequence: 20,
                part_list: [
                  {
                    id: "suspended_4_magnetic_track_2m",
                    product: {
                      id: "suspended_4_magnetic_track_2m",
                      name: "Suspended Magnetic Track DALI - 4 Wire",
                      code: "PRO-832-PRO-615",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_832_PRO_615.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-832-PRO-615",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 5,
                    price_unit: 16420,
                    price_total: 82100,
                  },
                  {
                    id: "suspended_4_magnetic_track_1m",
                    product: {
                      id: "suspended_4_magnetic_track_1m",
                      name: "Suspended Magnetic Track DALI - 4 Wire",
                      code: "PRO-831-PRO-615",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/profile_main_image/PRO_831_PRO_615.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-831-PRO-615",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 9600,
                    price_total: 9600,
                  },
                  {
                    id: "48v_non_dimmable_driver_200w",
                    product: {
                      id: "48v_non_dimmable_driver_200w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-200W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(200W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-200W",
                      wattage: 200,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 2,
                    price_unit: 2880,
                    price_total: 5760,
                  },
                  {
                    id: "48v_non_dimmable_driver_100w",
                    product: {
                      id: "48v_non_dimmable_driver_100w",
                      name: "48V Non-Dimmable Driver",
                      code: "PRO-48V-100W",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/driver_images/PRO_48V_(100W).png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-48V-100W",
                      wattage: 100,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 2,
                    price_unit: 1920,
                    price_total: 3840,
                  },
                  {
                    id: "track_spotlight_pro881",
                    product: {
                      id: "track_spotlight_pro881",
                      name: "8w",
                      code: "PRO-881",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_881.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-881",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: "3K",
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 8340,
                    price_total: 8340,
                  },
                  {
                    id: "track_spotlight_pro883",
                    product: {
                      id: "track_spotlight_pro883",
                      name: "19w",
                      code: "PRO-883",
                      primary_image:
                        "track_product_images/4_Wire_Magnetic_Track/fixtures_main_image/PRO_883.png",
                      brand: null,
                      fixed_details: null,
                    },
                    cart_properties: {
                      code: "PRO-883",
                      wattage: null,
                      lighting_control: null,
                      lighting_control_category: null,
                      track_shape: null,
                      track_dimension: null,
                      track_driver_quantity: null,
                      light_color: null,
                      body_color: null,
                      reflector_color: null,
                    },
                    quantity: 1,
                    price_unit: 11400,
                    price_total: 11400,
                  },
                ],
                price_unit: 121040,
                price_total: 121040,
              },
            ],
          },
        ],
      },
    ],
  },
};

const QuotationDetails = () => {
  const [openModal, setOpenModal] = useState(false);

  const { id: quataionID } = useParams();

  const project = false;

  // default cart states
  const [pageLoading, setPageLoading] = useState(false);
  const [cartSpecificData, setCartSpecificData] = useState<any>([]);
  const [showBanner, setShowBanner] = useState(false);
  const [checkout, enableCheckout] = useState(false);
  const [projectSelectionIndex, setProjectSelectionIndex] = useState(null);
  const isAuthenticated = true;
  const [bottomBarHeight, setBottomBarHeight] = useState(0); // State to track bottom bar height

  const projectId = "";

  // default cart api call
  useEffect(() => {
    setCartSpecificData(
      defaultCartDummyData?.data?.floors?.[0]?.areas?.[0]?.items
    );
  }, []);
  // default cart functions
  function calculateCost() {
    return null;
  }

  return (
    <div className="h-[calc(100dvh-40px)] flex flex-col justify-between items-start relative overflow-x-auto custom-scrollbar">
      <div className="h-full w-full">
        <div className="flex flex-col gap-3">
          {/* Quotation Number and Date */}
          <div className="flex gap-6 items-center border-b border-gray-200 px-6 py-3">
            <div className="flex items-center gap-2">
              <span className="font-gilroy-semi-bold text-sm">
                Quotation Number:
              </span>
              <span>#12345</span>
            </div>
            <div className="flex items-center gap-2 text-[#AEAEAE]">
              <span>25th March, 12:00 PM</span>
            </div>
          </div>

          {/* Project Details Section */}
          <div className="flex flex-col gap-4 border-b border-gray-200 px-6 py-2">
            <h2 className="font-gilroy-bold text-md">Project details</h2>

            <div className="grid grid-cols-2 gap-x-4 gap-y-4">
              <div>
                <p className="text-gray-600">Date created</p>
                <p className="font-gilroy-medium">25th March, 2023</p>
              </div>
              <div>
                <p className="text-gray-600">Total floors</p>
                <p className="font-gilroy-medium">10</p>
              </div>
              <div>
                <p className="text-gray-600">Location</p>
                <p className="font-gilroy-medium">Vadodara, Gujrat</p>
              </div>
              <div>
                <p className="text-gray-600">Total areas</p>
                <p className="font-gilroy-medium">45</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10 px-6">
          <LegendList />
        </div>

        {!project && (
          <DefaultQuotationTable
            loading={pageLoading}
            data={cartSpecificData}
            calculateCost={calculateCost}
            showBanner={showBanner}
            enableCheckout={enableCheckout}
            projectSelectionIndex={projectSelectionIndex}
            setProjectSelectionIndex={setProjectSelectionIndex}
            cartSpecificData={cartSpecificData}
            setCartSpecificData={setCartSpecificData}
            isAuthenticated={isAuthenticated}
            bottomBarHeight={bottomBarHeight}
          />
        )}
      </div>

      <BottomBar openModal={openModal} setOpenModal={setOpenModal} />
      <AddProductModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export default QuotationDetails;
