import React, { useEffect, useState } from "react";
import Modal from "../../components/common/Modal";
import Button from "../../components/Button";
import PlusIcon from "../../assets/PlusIcon";
import SelectCheckboxOption from "../../components/SelectCheckboxOption";
import SelectField2 from "../../components/SelectedField2";
import { getProjectQuicklist } from "../../api/project";
import { toast } from "react-toastify";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import { createQuotation } from "../../api/quotation";

const CreateQuotation = ({ getQuotationList }: any) => {
  const [page, setPage] = useState<number>(1);
  const [pageLength, setPageLength] = useState<number>(20);
  const [openModal, setOpenModal] = useState(false);
  const [selectedType, setSelectedType] = useState<"Regular" | "Project">(
    "Regular"
  );
  const [selectedProject, setSelectedProject] = useState<number | null>(null);
  const [projectList, setProjectList] = useState<any[]>([]);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const params = {
      page: page,
      length: pageLength,
    };
    getProjectQuicklist(params)
      .then((res) => {
        setProjectList(res?.data?.project);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, pageLength]);

  const handleSubmit = async () => {
    setIsLoading(true);
    // e.preventDefault();

    if (selectedType === "Project" && !selectedProject) {
      toast.error("Project is required");
    }
    const payload: any = {
      kind: selectedType.toUpperCase(),
      // discount_percentage: 0,
    };
    if (selectedType === "Project") {
      payload.project_id = selectedProject;
    }

    try {
      const response = await createQuotation(payload);
      if (response) {
        getQuotationList();
      }
      setIsLoading(false);
      setOpenModal(false);
    } catch (error) {
      console.log(error, "error");
      toast.error("Error While creating Quotation");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const conditionalMaxHeight = isSelectOpen ? "55" : "";
  return (
    <div className="flex flex-col gap-4">
      <div className="w-fit">
        <Button
          label="Create Quotation"
          leftIcon={<PlusIcon />}
          onClick={() => setOpenModal(true)}
        />
      </div>
      <form>
        {openModal && (
          <Modal
            clickOutsideToClose
            allowScroll
            header={"Create New Quotation"}
            footer={
              <Button
                variant="primary"
                label="Continue"
                onClick={handleSubmit}
                disabled={isLoading}
              />
            }
            handleCancel={() => setOpenModal(false)}
            // maxHeight={`!h-[${conditionalMaxHeight}vh]`}
          >
            <div className="flex flex-col gap-4">
              <p className="font-gilroy-semi-bold text-md pb-1  flex justify-start">
                Type
              </p>
              <div className="flex flex-col gap-4">
                <div className="flex gap-4 mb-2">
                  <SelectCheckboxOption
                    kind="Regular"
                    isSelected={selectedType === "Regular"}
                    handleSelection={() => setSelectedType("Regular")}
                    disabled={isLoading}
                  />
                  <SelectCheckboxOption
                    kind="Project"
                    isSelected={selectedType === "Project"}
                    handleSelection={() => setSelectedType("Project")}
                    disabled={isLoading}
                  />
                </div>
                {selectedType === "Project" && (
                  <div className="w-[30.5rem] mb-4">
                    <SelectField2
                      value={selectedProject}
                      label="Select a Project"
                      options={
                        projectList?.map((el: any) => ({
                          label: el.name,
                          value: el.id,
                        })) || []
                      }
                      onChange={(value: any) => setSelectedProject(value)}
                      searchPlaceHolder="Search project"
                      setIsSelectOpen={setIsSelectOpen}
                      disabled={isLoading}
                    />
                  </div>
                )}
              </div>
            </div>
          </Modal>
        )}
      </form>
    </div>
  );
};

export default CreateQuotation;
