import React, { useCallback, useState, useEffect, useMemo } from "react";
import InputField from "../../../components/InputField";
import TrashIcon from "../../../assets/TrashIcon";
import Button from "../../../components/Button";
import PlusIcon from "../../../assets/PlusIcon";
import { getSeries, postAssignedData } from "../../../api/product_series";
import {
  getProductSingleCategory,
  postProductCategory,
  updateProductCategory,
} from "../../../api/product_category";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageUpload from "../../../components/ImageUpload";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  productCategoryAtom,
  productSeriesAtom,
  titleAtom,
} from "../../../atom";
import SelectField2 from "../../../components/SelectedField2";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import _debounce from "lodash/debounce";
import Dropdown from "../productcomponents/Dropdown";
import TextArea from "../../../components/TextArea";
import Notfound from "../../404/components/Notfound";

const initState = {
  name: "",
  description: "",
  image: "",
  background_image: null,
  slug: "",
  is_active: false,
};

const EditCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [form, setForm] = useState<any>(initState);
  const [errors, setErrors] = useState<any>({});
  const [selectedAssignedData, setSelectedAssignedData] = useState<any>("");

  const [loading, setLoading] = useState(false);
  const setProductCategoryState = useSetRecoilState(productCategoryAtom);
  // this is for sidelist
  const [assignedData, setAssignedData] = useState<any[]>([]);
  // // this is for updated sidelist
  const [assignedNewData, setAssignedNewData] = useState<any[]>([]);
  // // this is for dropdown
  const [productData, setProductData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [seriesValue, setSeriesValue] = useState<string>();
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    setLoading(true);

    getProductSingleCategory(id)
      .then((res) => {
        const { name, description, image, is_active, slug, background_image } =
          res.data.category;

        setForm((prevForm: any) => ({
          ...prevForm,
          name: name,
          description: description,
          image: image,
          background_image: background_image || null,
          is_active: is_active,
          slug: slug,
        }));
      })
      .catch((err) => {
        console.error(err, "err");
        if (err?.status === 404) {
          setNotFound(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const handleAssignedDataChange = (assign: any) => {
    setSelectedAssignedData(assign);
  };

  const handleAddAssigned = () => {
    if (selectedAssignedData) {
      const { id, name } = selectedAssignedData;

      const isAlreadyAssigned = assignedNewData.some(
        (data) => data.value.id === id
      );
      if (isAlreadyAssigned) {
        toast.error("Already added to assigned");
        return;
      }
      if (!isAlreadyAssigned) {
        const newData: any = {
          value: { id: id, name: name },
          label: name,
        };
        setAssignedNewData((prevData: any) => [...prevData, newData]);
      }

      setSelectedAssignedData("");
    }
  };

  const handleSubmitForm = async () => {
    setLoading(true); // Set loading state to true before making the API call

    const newlyAddedItems = assignedNewData
      .filter(
        (newDataItem) =>
          !assignedData.some(
            (dataItem) => newDataItem.value.id === dataItem.value.id
          )
      )
      .map((el) => el.value.id);

    const newlyDeletedItems = assignedData
      .filter(
        (dataItem) =>
          !assignedNewData.some(
            (newDataItem) => newDataItem.value.id === dataItem.value.id
          )
      )
      .map((el) => el.value.id);

    try {
      const res = await updateProductCategory(id, form);
      const categoryId = res.data.category.id;

      let res2Success = false;
      if (newlyAddedItems.length > 0) {
        const payload = {
          changes: { category_id: categoryId },
          products: newlyAddedItems,
        };

        const res2 = await postAssignedData(payload);
        if (res2) {
          res2Success = true;
        }
      }
      let res3Success = false;
      if (newlyDeletedItems.length > 0) {
        const payload1 = {
          changes: { category_id: null },
          products: newlyDeletedItems,
        };

        const res3 = await postAssignedData(payload1);
        if (res3) {
          res3Success = true;
        }
      }

      if (
        res &&
        (newlyAddedItems.length === 0 || res2Success) &&
        (newlyDeletedItems.length === 0 || res3Success)
      ) {
        toast.success("Category updated successfully");
        navigate("/products/categories");
        setProductCategoryState([]);
      }
    } catch (e: any) {
      console.error(e);
      if (e?.data?.errors?._schema?.length === 1) {
        toast.error(e?.data?.errors?._schema?.[0]);
      } else if (e?.data?.errors?._schema?.length > 1) {
        toast.error(
          <ul className="list-disc pl-4">
            {e?.data?.errors?._schema?.map((errorMessage: any, i: number) => (
              <li key={i}>{errorMessage}</li>
            ))}
          </ul>
        );
      } else {
        toast.error("Category update failed");
      }

      setErrors(e?.data?.errors);
    } finally {
      setLoading(false); // Reset loading state to false after the API call completes
    }
  };

  useEffect(() => {
    const categoryId = id;
    protectedAxiosInstance
      .get("/admin/products", {
        params: { length: 100, category_id: categoryId },
      })

      .then((res) => {
        const assignedProduct = res.data.data.product;

        setAssignedData(
          assignedProduct.map((item: any) => ({
            value: { id: item.id, name: item.name },
            label: item.name,
          }))
        );
        setAssignedNewData(
          assignedProduct.map((item: any) => ({
            value: { id: item.id, name: item.name },
            label: item.name,
          }))
        );
      })
      .catch((error) => {});
  }, [id]);

  const [assignProductsPageLoading, setAssignProductsPageLoading] =
    useState(true);
  const fetchProducts = async () => {
    setAssignProductsPageLoading(true);
    protectedAxiosInstance
      .get("/admin/products", {
        params: { length: 20, page, search: searchText },
      })
      .then((res) => {
        const productList = res.data.data.product;

        setProductData((prevData: any) => [
          ...prevData,
          ...productList.map((item: any) => ({
            value: { id: item.id, name: item.name },
            label: item.name,
          })),
        ]);

        setTotalPage(res.data.data.pagination.total_pages);
        setAssignProductsPageLoading(false);
      })
      .catch((error: any) => {
        setAssignProductsPageLoading(false);
        // Handle errors
      });
  };
  const [time, setTime] = useState(500);

  const debouncedFetchProducts = _debounce(fetchProducts, time);
  useEffect(() => {
    setTime(10);
    const fetchData = async () => {
      await debouncedFetchProducts();
    };
    fetchData();
    return () => {
      debouncedFetchProducts.cancel();
    };
  }, [page]);

  useEffect(() => {
    setTime(500);
    setPage(1);
    setProductData([]);
    setAssignProductsPageLoading(true);
    const fetchData = async () => {
      await debouncedFetchProducts();
    };
    fetchData();
    return () => {
      debouncedFetchProducts.cancel();
    };
  }, [searchText]);

  useEffect(() => {
    const newlyUpdatedList = productData.filter(
      (newDataItem) =>
        !assignedNewData.some(
          (dataItem) => newDataItem.value.id === dataItem.value.id
        )
    );
    setProductData(newlyUpdatedList);
  }, [assignedNewData]);

  const handleDeleteAssignedData = async (id: string) => {
    const updatedAssignedProducts = assignedNewData.filter(
      (el: any) => el.value.id !== id
    );
    setAssignedNewData(updatedAssignedProducts);
  };

  const imageList = useMemo(
    () => (form.image ? [form.image] : []),
    [form.image]
  );

  const backgroundImageList = useMemo(
    () => (form.background_image ? [form.background_image] : []),
    [form.background_image]
  );

  const handleCancleForm = () => {
    setForm(initState);
    setSelectedAssignedData("");
    setAssignedNewData([]);
    navigate("/products/categories");
  };

  useEffect(() => {
    if (form?.name) {
      document.title = `Category - ${form?.name}`;
    } else {
      document.title = `Category - ...`;
    }
  }, [form?.name]);

  const handleBlur = (searchVal: any, key: string, list: any) => {
    const lowerCaseSearchVal = searchVal?.toLowerCase();
    const newList: any[] = [];
    list?.forEach((item: any) => {
      const currentVal = item?.name || item?.full_name;
      const lowercaseVal = currentVal?.toLowerCase();
      newList.push(lowercaseVal);
    });

    if (lowerCaseSearchVal !== "" && !newList.includes(lowerCaseSearchVal)) {
      setErrors((prev: any) => ({
        ...prev,
        [key]: "Please select a valid option!",
      }));
    } else if (list.includes(lowerCaseSearchVal)) {
      delete errors[key];
    }
  };

  if (notFound) {
    return <Notfound />;
  }

  return (
    <div className="h-[calc(100vh-2.5rem)] no-scrollbar w-full flex flex-col justify-start items-start">
      {/* header */}
      <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  px-4">
        <h2 className="font-semibold ">
          {id ? "Edit" : "Create New "} Category
        </h2>
      </div>

      {/* Content */}
      <div className="flex w-full justify-between flex-1  overflow-y-auto custom-scrollbar  py-3">
        <div className="w-[55%] overflow-y-auto custom-scrollbar px-4">
          <InputField
            disabled={loading}
            label="Category Name:"
            placeholder="Category Name"
            value={form.name}
            onChange={(e) => {
              if (errors.name) {
                delete errors.name;
                setErrors(structuredClone(errors));
              }
              setForm((prev: any) => ({ ...prev, name: e }));
            }}
            hint={errors.name || ""}
            required
          />
          <InputField
            disabled={loading}
            label="Category Slug:"
            placeholder="Category Slug"
            value={form.slug}
            onChange={(e) => {
              if (errors.slug) {
                delete errors.slug;
                setErrors(structuredClone(errors));
              }
              setForm((prev: any) => ({ ...prev, slug: e }));
            }}
            hint={errors.slug || ""}
            required
          />

          <TextArea
            className="w-full"
            onChange={(e) => {
              if (errors.description) {
                delete errors.description;
                setErrors(structuredClone(errors));
              }
              setForm((prev: any) => ({
                ...prev,
                description: e,
              }));
            }}
            value={form?.description}
            hint={errors?.description}
            required
            label="Description:"
            rows={5}
            disabled={loading}
          />

          {/* Upload img file */}
          <div className="w-full mt-2">
            <ImageUpload
              disabled={loading}
              kind="CATEGORY"
              imageList={imageList}
              setter={(value) => {
                form.image = value as string;
                setForm({ ...form });
              }}
              required
              label="Upload image:"
              errors={errors.image}
            />
          </div>
          <div className="w-full mt-2">
            <ImageUpload
              disabled={loading}
              label="Background Image:"
              kind="CATEGORY"
              imageList={backgroundImageList}
              setter={(value) => {
                form.background_image = value || null;
                setForm({ ...form });
              }}
              errors={errors.background_image}
            />
          </div>
          <div className="w-full flex flex-row items-center  gap-2 ">
            <div className="w-full ">
              <SelectField2
                loading={assignProductsPageLoading}
                totalPage={totalPage}
                page={page}
                setPage={setPage}
                disabled={loading}
                label="Assign products:"
                options={productData}
                value={selectedAssignedData}
                onChange={handleAssignedDataChange}
                placeholder="Select an assigned product"
                externalSearch={searchText}
                externalSetSearch={setSearchText}
              />
            </div>
            <div
              onClick={handleAddAssigned}
              className="px-1 h-10 mt-6 flex items-center cursor-pointer"
            >
              <PlusIcon color={loading ? "#DEDEDE" : "#000000"} width="14" />
            </div>
          </div>
        </div>

        <div className="px-4 border-r border-[#ECECEC]"></div>

        {/* Assigned Product */}
        <div className="w-[45%] px-4 flex flex-col gap-0 ">
          <div className="flex flex-col items-start ">
            <p className="font-gilroy-bold">List of products assigned:</p>
          </div>
          <div className="w-full flex bg-[#ECECEC]/80 mt-4 font-gilroy-bold divide-x-2 ">
            <div className="w-[5vw] px-2 py-3">Sr. no</div>
            <div className="w-full flex justify-start px-4 py-3">
              Product name
            </div>
          </div>

          <div className="flex flex-col justify-start items-start divide-y overflow-y-auto custom-scrollbar">
            {assignedNewData.map((el: any, index) => (
              <div key={index} className="w-full flex bg-white divide-x">
                <div className="w-[5vw] px-2 py-2">{index + 1}</div>
                <div className="w-full flex justify-between items-center px-4 py-2">
                  <p>{el?.value?.name}</p>
                  <div
                    onClick={() => handleDeleteAssignedData(el.value.id)}
                    className="cursor-pointer"
                  >
                    <TrashIcon />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex gap-x-4 w-[100%] py-3 border-t  border-[#ECECEC] px-4">
        <Button
          label="Edit Category"
          onClick={handleSubmitForm}
          disabled={loading}
        />
        <Button
          variant="secondary-outline"
          label="Cancel"
          onClick={handleCancleForm}
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default EditCategory;
