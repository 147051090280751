import React from "react";

interface LegendProps {
  colour: string;
  label?: string;
}

const Legend = ({ colour, label }: LegendProps) => {
  function applyBackground() {
    return `bg-[${colour}]`;
  }

  function applyBorder() {
    if (colour === "#FFFFFF") {
      return "border-[#CBCBCB]";
    }
    return `border-[${colour}]`;
  }

  return (
    <div className="flex items-center gap-3">
      <div
        className={`w-[18px] h-[18px] border`}
        style={{
          backgroundColor: colour,
          borderColor: colour === "#FFFFFF" ? "#CBCBCB" : colour,
        }}
      ></div>
      <p className="text-black text-xs font-gilroy-semi-bold leading-[18px]">
        {label}
      </p>
    </div>
  );
};

export default Legend;
