import React from "react";

const CarretIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        d="M5.65707 7.20594C5.44232 7.5779 4.90544 7.5779 4.69069 7.20594L1.30838 1.3476C1.09363 0.975646 1.36207 0.510699 1.79157 0.510699L8.55619 0.510699C8.98569 0.510699 9.25413 0.975646 9.03938 1.3476L5.65707 7.20594Z"
        // fill="#434343"
        // stroke="#434343"
        strokeWidth="0.836905"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};

export default CarretIcon;
