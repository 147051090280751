import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import useOnClickOutside from "../hooks/useClickOutside";
import HipchatChevronDownIcon from "../assets/HipchatChevronDownIcon";
import { MainAppSideBarExpandAtom } from "../atom";
import { useRecoilValue } from "recoil";
import InputField from "./InputField";

import ErrorMessage from "./ErrorMessage";
import CrossIcon from "../assets/CrossIcon";
import { DownwardArrowIcon } from "../assets/ArrowIcons";
import { removeExtraSpaces } from "../utils/helper";
import FilterIcon from "../assets/svg-tsx/FilterIcon";

type Option = {
  value?: any;
  label?: string;
};

type SelectFieldProps = {
  label?: string;
  options: (() => Option[]) | Option[];
  value: any | undefined;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  page?: any;
  setPage?: any;
  totalPage?: any;
  searchPlaceHolder?: any;
  externalSearch?: any;
  externalSetSearch?: any;
  loading?: boolean;
  filterLogic?: (options: Option[]) => Option[];
  hint?: any;
  required?: boolean;
  removeOption?: any;
  selectCSS?: string;
  targetRef?: any;
  autoComplete?: any; // new-street-address new-city new-state new-postal-code new-country new-password new-email
  leftIcon?: any;
  setIsSelectOpen?: (value: boolean) => void;
};

const SelectField2 = ({
  options,
  value,
  label,
  required,
  onChange,
  placeholder = "Select an option",
  searchPlaceHolder,
  disabled = false,
  page = 1,
  setPage = () => {},
  totalPage = 1,
  externalSearch,
  externalSetSearch,
  loading = false,
  filterLogic,
  hint,
  removeOption,
  selectCSS,
  targetRef,
  autoComplete = "off",
  leftIcon,
  setIsSelectOpen,
}: SelectFieldProps) => {
  const [search, setSearch] = useState("");
  // Use externalSearch and externalSetSearch if provided, otherwise use local state
  const searchValue = externalSearch !== undefined ? externalSearch : search;
  const setSearchValue =
    externalSetSearch !== undefined ? externalSetSearch : setSearch;
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const listRef = useRef<any>(null);

  useOnClickOutside(selectRef, () => {
    setSearchValue("");
    setIsOpen(false);
    setIsSelectOpen && setIsSelectOpen(false);
  });

  const handleOptionClick = (option: Option): void => {
    setIsOpen(false);
    setSearchValue("");
    const selectedValue = option.value || "";
    onChange(selectedValue);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (listRef?.current) {
        const { scrollTop, scrollHeight, clientHeight }: any = listRef.current;
        if (
          totalPage !== undefined &&
          page < totalPage &&
          scrollTop + clientHeight >= scrollHeight - 200
        ) {
          setPage(page + 1);
        }
      }
    };

    const list: any = listRef.current;

    if (list) {
      list.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (list) {
        list.removeEventListener("scroll", handleScroll);
      }
    };
  });

  const handleRemoveOption = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    removeOption();
  };

  let filteredOptions = options || [];

  if (typeof options === "function") {
    filteredOptions = (options as () => Option[])();
  } else {
    // Synchronous case
    filteredOptions = options as Option[];
  }

  if (!externalSearch) {
    filteredOptions = filteredOptions?.filter((option) => {
      const label =
        typeof option.label === "string" &&
        option.label
          .toLowerCase()
          .includes(removeExtraSpaces(searchValue)?.toLowerCase());
      const value =
        typeof option.value === "string" &&
        option.value
          .toLowerCase()
          .includes(removeExtraSpaces(searchValue)?.toLowerCase());
      return label || value;
    });
  }

  useEffect(() => {
    setIsSelectOpen && setIsSelectOpen(isOpen);
  }, [isOpen]);
  
  return (
    <div ref={targetRef}>
      <div
        ref={selectRef}
        className={` flex flex-col justify-start items-start ${
          selectCSS ? selectCSS : "min-w-[13.5rem]"
        } max-w-[100%] text-[12px]`}
      >
        {label && (
          <p className="font-gilroy-semi-bold pb-1 flex justify-start">
            {label} {required && <span className="text-red-500 ml-1">*</span>}
          </p>
        )}
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={`relative w-full flex justify-between items-center px-0 py-1.5 bg-white rounded-md cursor-pointer border-2 border-[#DEDEDE] ${
            disabled
              ? "opacity-30 cursor-not-allowed"
              : "opacity-100 hover:border-2 hover:border-[#D4A15E] hover:border-opacity-50 outline-none focus:bg-white focus:border-pot-yellow"
          }`}
        >
          {value && !isOpen ? (
            <span
              className={`flex items-center gap-2 truncate font-gilroy-regular px-2 text-left`}
            >
              {" "}
              {leftIcon}
              {value !== "" ? (
                // Check if options is a function and call it if it is
                typeof options === "function" ? (
                  options()?.find((option) => option.value === value)?.label ||
                  value
                ) : (
                  // If options is not a function, it's a synchronous array
                  options?.find((option) => option.value === value)?.label ||
                  value
                )
              ) : (
                <span className="text-[#AEAEAE] px-2 ">{placeholder}</span>
              )}
            </span>
          ) : searchPlaceHolder !== undefined ? (
            <input
              autoComplete={autoComplete}
              type="text"
              value={searchValue}
              className="text-black px-2 font-normal w-full outline-none focus:bg-white placeholder-shown:text-pot-grey2 sticky top-0 font-gilroy-regular  leading-5 "
              onChange={(e) => {
                if (!isOpen) setIsOpen(true);
                setSearchValue(e.target.value);
              }}
              placeholder={searchPlaceHolder}
              onClick={(e) => {
                e.stopPropagation();
                if (!isOpen) setIsOpen(true);
              }}
            />
          ) : (
            <span
              className={`flex gap-2 items-center truncate font-gilroy-regular w-[90%] px-2`}
            >
              {leftIcon}
              <span className="text-[#AEAEAE]">{placeholder}</span>
            </span>
          )}

          {!value || !removeOption ? (
            <motion.div
              animate={{ rotate: isOpen && !disabled ? 180 : 0 }}
              className="w-fit h-fit flex items-center justify-center py-[1px] px-2"
            >
              <DownwardArrowIcon color="black" />
            </motion.div>
          ) : (
            <div
              className="scale-105  p-[5.5px] px-2"
              onClick={handleRemoveOption}
            >
              <CrossIcon color="black" />
            </div>
          )}
          {isOpen && !disabled && (
            <div
              ref={listRef}
              className="w-full  bg-white rounded-md shadow-md mt-1 z-[100] text-start overflow-y-auto custom-scrollbar  absolute top-[calc(100%+5px)] max-h-[150px]"
            >
              <ul className={`h-auto overflow-y-auto`}>
                {filterLogic
                  ? filterLogic(filteredOptions).map((option) => (
                      <li
                        key={option.value}
                        onClick={() => handleOptionClick(option)}
                        className={`px-4 py-2 cursor-pointer hover:bg-gray-100 font-gilroy-regular leading-5 ${
                          option.value === value ? "bg-gray-100" : ""
                        }`}
                      >
                        {option.label}
                      </li>
                    ))
                  : filteredOptions?.map((option) => (
                      <li
                        key={option.value}
                        onClick={() => handleOptionClick(option)}
                        className={`px-4 py-2 cursor-pointer hover:bg-gray-100 font-gilroy-regular leading-5  ${
                          option.value === value ? "bg-gray-100" : ""
                        }`}
                      >
                        {option.label}
                      </li>
                    ))}
                {loading ? <p>Loading...</p> : null}
                {filteredOptions?.length === 0 && !loading ? (
                  <p className="px-4 py-2 font-gilroy-regular leading-5">
                    No options available
                  </p>
                ) : null}
              </ul>
            </div>
          )}
        </div>
        <ErrorMessage error={hint} />
      </div>
    </div>
  );
};

export default SelectField2;
