import React from "react";

export type IconProp = { color?: string; width?: string; height?: string };

export default function AreaIcon({ color, width, height }: IconProp) {
  return (
    <svg
      width={width || "18"}
      height={height || "18"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3333 0.75H1.66667C1.42355 0.75 1.19039 0.846577 1.01849 1.01849C0.846577 1.19039 0.75 1.42355 0.75 1.66667V16.3333C0.75 16.5764 0.846577 16.8096 1.01849 16.9815C1.19039 17.1534 1.42355 17.25 1.66667 17.25H16.3333C16.5764 17.25 16.8096 17.1534 16.9815 16.9815C17.1534 16.8096 17.25 16.5764 17.25 16.3333V1.66667C17.25 1.42355 17.1534 1.19039 16.9815 1.01849C16.8096 0.846577 16.5764 0.75 16.3333 0.75ZM8.08333 15.4167H2.58333V9.91667H8.08333V15.4167ZM8.08333 8.08333H2.58333V2.58333H8.08333V8.08333ZM15.4167 15.4167H9.91667V9.91667H15.4167V15.4167ZM15.4167 8.08333H9.91667V2.58333H15.4167V8.08333Z"
        fill={color || "currentColor"}
      />
    </svg>
  );
}
