import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import Link from "next/link";
import CrossIcon from "../../../assets/CrossIcon";
import Button from "../../../components/Button";
import ProductIcon from "../../../assets/svg-tsx/ProductIcon";
import FloorIcon from "../../../assets/svg-tsx/FloorIcon";
import AreaIcon from "../../../assets/svg-tsx/AreaIcon";

const BottomBar = ({
  cost,
  professional,
  selectedProject,
  handleDeleteProfessional,
  checkout,
  setShowProfessionalModal,
  costLoading,
  checkoutTooltipMessage = "",
  setBottomBarHeight,
  setOpenModal,
}: any) => {
  //   const isAuthenticated = !!Cookies.get("refresh_token");
  // //   const [previousPath, setPreviousPath] = useRecoilState(PreviousPathAtom);
  //   const [openTooltip, setopenTooltip] = useState(false);
  //   const router = useRouter();
  //   const bottomBarRef = useRef<HTMLDivElement>(null);

  //   const [windowWidth, setWindowWidth] = useState(globalThis.innerWidth);

  //   useEffect(() => {
  //     const handleResize = () => {
  //       setWindowWidth(globalThis.innerWidth);
  //     };
  //     window.addEventListener("resize", handleResize);
  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   }, []);

  //   useEffect(() => {
  //     const bottomBarElement = bottomBarRef.current;

  //     if (bottomBarElement) {
  //       // Initialize ResizeObserver to watch for changes in height
  //       const resizeObserver = new ResizeObserver(() => {
  //         const newHeight = bottomBarElement.offsetHeight;
  //         setBottomBarHeight(newHeight);
  //       });

  //       // Observe the bottomBar element
  //       resizeObserver.observe(bottomBarElement);

  //       // Cleanup the observer on unmount
  //       return () => resizeObserver.disconnect();
  //     }
  //   }, []);

  return (
    <div
      className="w-full sticky z-20 bottom-0 bg-pot-black flex justify-between items-center px-0 lg:px-2 lg:py-0 flex-col lg:flex-row"
      //   ref={bottomBarRef}
    >
      <div className="hidden flex-1 w-full lg:flex gap-1 justify-between items-center text-white font-gilroy-semibold text-xs md:text-sm lg:text-base px-2 md:px-8 ">
        <button
          onClick={() => setOpenModal(true)}
          className="cursor-pointer flex gap-2"
        >
          <FloorIcon />
          Add a Floor
        </button>
        <button
          onClick={() => setOpenModal(true)}
          className="cursor-pointer flex gap-2"
        >
          <AreaIcon />
          Add an Area
        </button>
        <button
          onClick={() => setOpenModal(true)}
          className="cursor-pointer flex gap-2"
        >
          <ProductIcon width="22" />
          Add a Product
        </button>
      </div>

      <div className="flex-1 w-full  pt-2 pb-1 border-b border-b-c-black-8 lg:py-0 lg:border-b-0">
        {professional?.name ? (
          <div className="flex  justify-center h-auto items-center gap-3 ">
            <div
              className="cursor-pointer "
              onClick={() => handleDeleteProfessional()}
            >
              <CrossIcon color="#fff" width="12" />
            </div>
            <p className=" md:text-2xl lg:text-3xl font-bold  text-pot-yellow text-center tracking-wider">
              {"PROFESSIONAL: " + professional?.name}
            </p>
          </div>
        ) : (
          <p
            className="w-full text-pot-yellow text-center text-2xl font-mangoGrotesque-black cursor-pointer "
            // onClick={() => {
            //   setShowProfessionalModal(true);
            // }}
          >
            Add a Discount
          </p>
        )}
      </div>

      <div className="flex-1 w-full flex justify-between lg:justify-end items-center text-white font-gilroy-bold text-xs md:text-sm lg:text-lg md:px-8 gap-3 pr-2">
        {/* {cost?.discount_professional_referral && !costLoading ? (
          <div className="flex text-base md:text-lg xl:text-2xl line-through decoration-pot-yellow decoration-4 justify-center ">
            <p>₹</p>
            <p>
              {cost?.sub_total
                ? (parseFloat(cost.crossed_total) / 100).toLocaleString(
                    "en-IN",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )
                : "0.00"}
            </p>
          </div>
        ) : (
          ""
        )} */}

        <div className="text-xl md:text-2xl xl:text-3xl text-pot-yellow whitespace-nowrap text-right flex">
          <p>₹</p>
          <p>
            {!costLoading
              ? cost?.sub_total
                ? (parseFloat(cost.grand_total) / 100).toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : "0.00"
              : "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BottomBar;
