import FileUpload from "../../../components/FileUpload";

const ProductFiles = ({ loading, productFileRef, form, setForm }: any) => {
  return (
    <div className="flex flex-col justify-between w-[90%]  mt-8 ">
      <FileUpload
        label="Product Files"
        targetRef={productFileRef}
        required
        disabled={loading}
        isMulti
        kind="PRODUCT_DOCUMENT"
        setter={(values: any) => {
          const formattedDocuments = values.map((doc: any) => {
            if (Array.isArray(doc)) {
              return [doc[0], doc[1]];
            }
            return [doc.filename, doc.path];
          });
          form.documents = formattedDocuments;
          setForm(structuredClone(form));
        }}
        imageList={form.documents}
        primary={form.primary_document}
      />
    </div>
  );
};

export default ProductFiles;
