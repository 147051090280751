import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import CrossIcon from "../assets/CrossIcon";
import { protectedAxiosInstance } from "../api/axiosManagement";
import LoadingSpinner from "../assets/images/LoadingSpinner.gif";
import { toast } from "react-toastify";
import ErrorMessage from "./ErrorMessage";
import PlusIcon from "../assets/PlusIcon";
import Button from "./Button";
import FileIcon from "../assets/svg-tsx/FileIcon";
import FileDownloadIcon from "../assets/svg-tsx/FileDownloadIcon";

type FileUploadPropType = {
  isMulti?: boolean;
  kind: "PRODUCT" | "CATEGORY" | "PRODUCT_DOCUMENT";
  setter: (values: any) => void;
  imageList: any[];
  primary?: string;
  primarySetter?: (value: string) => void;
  errors?: string[];
  removeError?: () => void;
  maxSize?: number;
  disabled?: boolean;
  required?: boolean;
  label?: string | undefined;
  targetRef?: any;
};

const FileUpload = ({
  isMulti = false,
  kind,
  setter,
  imageList,
  primary = "",
  primarySetter = () => {},
  errors = [],
  removeError = () => {},
  maxSize = 20,
  disabled = false,
  required = false,
  label,
  targetRef,
}: FileUploadPropType) => {
  const [showUploadDiv, setShowUploadDiv] = useState(true);
  const [loading, setLoading] = useState(false);

  const get_signed = async (extension: string) => {
    const payload = {
      extension,
      kind,
    };
    const { data: response } = await protectedAxiosInstance.post(
      `${process.env.REACT_APP_API_DOMAIN}/admin/products/generate-upload-url`, //eslint-disable-line
      payload
    );

    return response.data;
  };

  const signed_to_s3 = async (acceptedFile: File) => {
    const extension = acceptedFile.name.split(".").at(-1)?.toLowerCase();
    const { file_path, upload_url } = await get_signed(extension as string);

    const file = new FormData();
    file.append("file", acceptedFile);

    let contentType: string;

    if (extension === "jpeg" || extension === "jpg") {
      contentType = "image/jpeg";
    } else if (extension === "png") {
      contentType = "image/png";
    } else if (extension === "webp") {
      contentType = "image/webp";
    } else if (extension === "pdf") {
      contentType = "application/pdf";
    } else if (extension === "dwg") {
      contentType = "application/octet-stream";
    } else if (
      extension === "ies" ||
      extension === "ldt" ||
      extension === "eulumdat"
    ) {
      contentType = "application/octet-stream";
    } else {
      contentType = "";
    }

    const response = await fetch(upload_url, {
      method: "PUT",
      body: acceptedFile,
      headers: {
        "Content-Type": contentType,
      },
    });

    return { response, file_path };
  };

  const onDrop = async (acceptedFiles: File[], rejectedFiles: any) => {
    // Check if the total number of files exceeds 5
    if (imageList?.length + acceptedFiles?.length > 5) {
      toast.error("You can only upload a maximum of 5 files.");
      return;
    }

    // Handle rejected files for size and type errors
    if (
      rejectedFiles.some((file: any) =>
        file.errors?.some((error: any) => error.code === "file-too-large")
      )
    ) {
      toast.error("File size exceeded. Maximum allowed size is 10MB.");
    }

    if (
      rejectedFiles.some((file: any) =>
        file.errors?.some((error: any) => error.code === "file-invalid-type")
      )
    ) {
      toast.error(
        "Invalid file format selected. Only .pdf, .dwg, .ies, .ldt, .eulumdat are accepted."
      );
    }

    // Return early if no accepted files
    if (acceptedFiles.length === 0) return;

    setLoading(true);

    // Handle any existing errors
    if (errors.length) {
      removeError();
    }

    // Check for oversized files
    const maxSizeBytes = maxSize * 1024 * 1024;
    const oversizedFiles = acceptedFiles.filter(
      (file) => file.size > maxSizeBytes
    );

    if (oversizedFiles.length > 0) {
      toast.error("File exceed the maximum size limit.");
      setLoading(false);
      return;
    }

    const validFiles: File[] = [];

    // Check dimensions of each file
    for (const file of acceptedFiles) {
      // const isValid = await checkFileDimensions(file);
      validFiles.push(file);
      // if (isValid) {
      // validFiles.push(file);
      // } else {
      //   invalidFiles.push(file);
      // }
    }

    if (validFiles.length === 0) {
      setLoading(false);
      return;
    }

    // Upload the valid files
    try {
      if (!showUploadDiv) return;

      if (!isMulti) {
        const { response, file_path } = await signed_to_s3(validFiles[0]);
        setter({ filename: validFiles[0].name, path: file_path });
        setShowUploadDiv(false);
      } else {
        const pathList = await Promise.all(
          validFiles.map(async (file) => {
            const { response, file_path } = await signed_to_s3(file);
            return { filename: file.name, path: file_path };
          })
        );
        setter([...(imageList || []), ...pathList]);

        if (!primary) {
          primarySetter(pathList[0].path);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (image: string) => {
    if (errors.length) {
      removeError();
    }
    if (isMulti) {
      const filteredList = imageList.filter((each) => each !== image);

      if (primary === image) {
        if (filteredList.length) {
          primarySetter(filteredList[0]);
        } else {
          primarySetter("");
        }
      }
      setter(filteredList);
    } else {
      setter("");
      setShowUploadDiv(true);
    }
  };

  const downloadFile = async (filePath: string, fileName: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BUCKET}/${filePath}`
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Error downloading file");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "application/octet-stream": [".dwg", ".ies", ".ldt", ".eulumdat"],
    },
    onDrop,
    maxSize: 10 * 1024 * 1024,
  });

  useEffect(() => {
    if (imageList?.length && !isMulti) {
      setShowUploadDiv(false);
    }
  }, [imageList]);

  return (
    <div ref={targetRef} className="min-h-[140px]">
      <div className="flex flex-row justify-between items-center w-full ">
        <div>
          {label && <p className="font-gilroy-semi-bold text-base">{label}</p>}
        </div>
        <div>
          {showUploadDiv && !loading && (
            <div
              {...getRootProps()}
              className={`flex items-center justify-center w-full mb-2 ${
                disabled ? " opacity-30" : ""
              }`}
            >
              <label
                className={`flex flex-col items-center justify-center w-full ${
                  disabled ? "cursor-not-allowed" : "cursor-pointer"
                }`}
              >
                <Button
                  variant="secondary"
                  onClick={(e: any) => e?.stopPropagation()}
                  label="Add"
                  leftIcon={<PlusIcon color="white" />}
                />
                <input {...getInputProps()} />
              </label>
            </div>
          )}
        </div>
      </div>

      <ErrorMessage error={errors} />

      {loading ? (
        <img src={LoadingSpinner} alt="spinner" className="h-[70%] w-[60%]" />
      ) : (
        <div className="grid lg:grid-cols-1 xl:grid-cols-2 gap-4 my-4">
          {imageList?.map((each) => {
            return (
              <div
                key={each}
                className="flex items-center justify-between border p-3 hover:shadow-sm"
              >
                <div className="flex items-center gap-2 flex-1 min-w-0">
                  <span className="flex-shrink-0">
                    <FileIcon width="22px" />
                  </span>
                  <span
                    className="text-sm truncate hover:cursor-pointer"
                    title={each[0]}
                  >
                    {each[0]}
                  </span>
                </div>
                <div className="flex items-center gap-3 flex-shrink-0 ml-2">
                  <button
                    onClick={() => downloadFile(each[1], each[0])}
                    className="text-blue-500"
                  >
                    <FileDownloadIcon size="14px" />
                  </button>
                  <button
                    onClick={() => handleDelete(each)}
                    className="text-red-500"
                  >
                    <CrossIcon color="black" width="13px" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
