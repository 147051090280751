import { useRecoilState } from "recoil";
import { stateAtom } from "../../../atom";
import { useEffect, useRef, useState } from "react";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import { toast } from "react-toastify";
import Modal from "../../../components/common/Modal";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import SelectField2 from "../../../components/SelectedField2";
import Checkbox from "../../../components/Checkbox";
import ProfessionalDirectoryDropdown from "../../../components/ProfessionalDirectoryDropdown";
import { getProfessional } from "../../../api/professional";
import { removeNullValues } from "../../../utils/validations";
import ProfessionalDropdown from "../../../components/ProfessionalDropdown";
import { fieldOptions } from "../../../utils/data";

const CreateEditExperience = ({
  setShowExperienceModal,
  selectedXp,
  setSelectedXp,
  reRender,
  professionalID,
  companyCategoriesOptions,
  setSearchQuery,
  searchQuery,
  companyList,
  setCompanyList,
}: any) => {
  const [stateList, setStateList] = useRecoilState<any>(stateAtom);
  const [formData, setFormData] = useState<any>();
  const [checked, setChecked] = useState<any>(0);
  const [errors, setErrors] = useState<any>();
  const [companyId, setCompanyId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Month Dropdown Options
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthOptions = months.map((month, index) => ({
    value: index + 1,
    label: month,
  }));

  // Year Dropdown Options
  const currentYear = new Date().getFullYear();

  const yearOptions = Array.from(
    { length: 100 },
    (_, index) => currentYear - index
  ).map((el: any) => ({
    value: el.toString(),
    label: el.toString(),
  }));

  const payload: any = {
    title: formData?.title,
    start_y: formData?.start_y,
    start_m: formData?.start_m,
    location_state: formData?.location_state,
    location_city: formData?.location_city,
    is_current: checked === 1 ? true : false,
    p_company_id: companyId,
  };

  if (checked === 0) {
    payload.end_m = formData?.end_m;
    payload.end_y = formData?.end_y;
  } else {
    payload.end_m = null;
    payload.end_y = null;
  }

  const close = () => {
    setSelectedXp(null);
    setShowExperienceModal(false);
  };

  // save button functionality
  const handleSave = () => {
    setIsLoading(true);
    if (selectedXp) {
      protectedAxiosInstance
        .put(
          `/admin/accounts/professional/${professionalID}/workx/${selectedXp}`,
          payload
        )
        .then((res) => {
          setIsLoading(false);
          close();
          reRender();
        })
        .catch((error) => {
          setIsLoading(false);
          setErrors(error?.data?.errors);
          toast.error(error?.data?.errors?._schema?.[0]);
        });
    } else {
      protectedAxiosInstance
        .post(`/admin/accounts/professional/${professionalID}/workx`, payload)
        .then((res) => {
          setIsLoading(false);
          close();
          reRender();
        })
        .catch((error: any) => {
          setIsLoading(false);
          setErrors(error?.data?.errors);
          toast.error(error?.data?.errors?._schema?.[0]);
        });
    }
  };

  const handleCheck = () => {
    if (checked === 0) {
      delete formData?.end_m;
      delete formData?.end_y;
      delete errors?.end_m;
      delete errors?.end_y;
    }
    setChecked((prev: any) => (prev === 0 ? 1 : 0));
  };

  const handleDelete = () => {
    protectedAxiosInstance
      .delete(
        `/admin/accounts/professional/${professionalID}/workx/${selectedXp}`
      )
      .then((res) => {
        toast.success("Experience Deleted Successfully!");
        reRender();
        close();
      });
  };

  useEffect(() => {
    if (selectedXp) {
      setIsLoading(true);
      protectedAxiosInstance
        .get(
          `/admin/accounts/professional/${professionalID}/workx/${selectedXp}`
        )
        .then((res: any) => {
          const response = res?.data?.data?.workx;
          if (!response.end_m && !response.end_y) {
            setChecked(1);
          }
          setFormData(response);

          setCompanyId(res?.data?.data?.workx?.p_company?.id);
        })
        .catch((err) => {
          toast.error("Error in getting experience details");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedXp]);

  const handleClear = () => {
    setCompanyId("");
  };

  // Update monthOptions based on the selected start year
  const updatedMonthOptions = monthOptions.filter((option: any) => {
    if (
      formData?.start_y === currentYear.toString() &&
      parseInt(option.value) > new Date().getMonth() + 1
    ) {
      return false; // Hide future months if the start year is the current year
    }
    return true;
  });
  // Update yearOptions based on the selected start year
  const updatedYearOptions = yearOptions.filter((option) => {
    const selectedYear = parseInt(formData?.start_y);
    const currentYear = new Date().getFullYear();
    if (selectedYear === currentYear && parseInt(option.value) > currentYear) {
      return false; // Hide future years if the start year is the current year
    }
    return true;
  });

  return (
    <Modal
      allowScroll
      maxHeight="max-h-[90vh]"
      header={selectedXp ? "UPDATE EXPERIENCE" : "ADD EXPERIENCE"}
      footer={
        <div
          className={`flex ${
            selectedXp ? "justify-between" : "justify-start"
          } items-center w-full`}
        >
          <Button
            label={selectedXp ? "Update" : "Save"}
            variant="primary"
            onClick={handleSave}
            disabled={isLoading || formData?.status === "DECLINED"}
          />

          {selectedXp && (
            <div
              className={`flex py-2 pr-2 text-sm font-gilroy-bold   ${
                isLoading
                  ? "opacity-75 cursor-not-allowed text-red-400"
                  : "opacity-100 cursor-pointer text-red-500"
              }`}
              onClick={() => {
                if (isLoading) {
                  return;
                } else {
                  handleDelete();
                }
              }}
            >
              <span className="sm:hidden">Delete</span>
              <span className="max-sm:hidden">Delete this experience</span>
            </div>
          )}
        </div>
      }
      handleCancel={close}
    >
      <div
        className="flex flex-col gap-4 w-full"
        onClick={(e) => e.stopPropagation()}
      >
        {/* <InputField
          label="Title"
          onChange={(e: any) => {
            setFormData((prev: any) => ({
              ...prev,
              title: e,
            }));
            delete errors?.title;
          }}
          hint={errors?.title}
          value={formData?.title || ""}
          placeholder="Ex. Landscape designer"
          disabled={isLoading || formData?.status === "DECLINED"}
          required
        /> */}

        <SelectField2
          label="Title"
          required
          options={fieldOptions}
          searchPlaceHolder="Ex. Landscape designer"
          value={formData?.title || ""}
          onChange={(e: any) => {
            setFormData((prev: any) => ({
              ...prev,
              title: e,
            }));
            delete errors?.title;
          }}
          removeOption={() => {
            setFormData((prev: any) => ({
              ...prev,
              title: null,
            }));
            delete errors?.title;
          }}
          hint={errors?.title}
          disabled={isLoading || formData?.status === "DECLINED"}
        />

        <ProfessionalDropdown
          required
          smallText
          width="100%"
          label="Company:"
          Xsearch={searchQuery}
          setXSearch={setSearchQuery}
          options={companyList.map((el: any) => ({
            value: el?.p_company_id,
            label: el,
          }))}
          onChange={(e: any) => {
            setCompanyId(e);
            delete errors?.p_company_id;
          }}
          hint={errors?.p_company_id}
          value={companyId}
          searchPlaceHolder="Search your company"
          placeholder="Choose your company"
          onClear={handleClear}
          // companyCategoriesOptions={companyCategoriesOptions}
          disabled={isLoading || formData?.status === "DECLINED"}
        />

        <SelectField2
          autoComplete={"new-street-address"}
          required
          searchPlaceHolder="Search city or state here"
          options={stateList
            ?.map((state: any) => {
              return state?.cities?.map((city: any) => ({
                value: `${city}, ${state.state_code}`,
                label: `${city}, ${state.name}`,
              }));
            })
            .flat(1)}
          value={
            formData?.location_city && formData.location_state
              ? `${formData?.location_city}, ${formData.location_state}`
              : ""
          }
          label="Location:"
          onChange={(e: any) => {
            const string = e.split(", ");
            setFormData((prev: any) => ({
              ...prev,
              location_city: string[0],
              location_state: string[1],
            }));
            delete errors?.location_city;
            delete errors?.location_state;
          }}
          placeholder="City, State"
          disabled={isLoading || formData?.status === "DECLINED"}
          hint={errors?.location_city || errors?.location_state}
        />

        <div className="flex flex-col sm:flex-row gap-4 [&>*]:flex-1">
          <SelectField2
            required
            label="Start Date Month:"
            options={
              formData?.start_y == new Date().getFullYear()
                ? [...monthOptions]?.splice(0, new Date().getMonth() + 1)
                : monthOptions
            }
            searchPlaceHolder="Search month"
            value={formData?.start_m || ""}
            onChange={(e) => {
              setFormData((prev: any) => ({
                ...prev,
                start_m: e,
              }));
              delete errors?.start_m;
            }}
            hint={errors?.start_m}
            placeholder="Choose month"
            disabled={isLoading || formData?.status === "DECLINED"}
          />
          <SelectField2
            required
            label="Start Date Year:"
            options={yearOptions}
            searchPlaceHolder="Search year"
            value={formData?.start_y || ""}
            onChange={(e: any) => {
              setFormData((prev: any) => ({
                ...prev,
                start_y: e,
              }));
              if (
                e == new Date().getFullYear() &&
                formData?.start_m > new Date().getMonth() + 1
              ) {
                setFormData((prev: any) => ({
                  ...prev,
                  start_m: "",
                }));
              }
              delete errors?.start_y;
            }}
            hint={errors?.start_y}
            placeholder="Choose year"
            disabled={isLoading || formData?.status === "DECLINED"}
          />
        </div>

        <div className="flex gap-2">
          <Checkbox
            label="I am currently working in this role"
            checkValue={checked}
            onClick={handleCheck}
            disabled={isLoading || formData?.status === "DECLINED"}
          />
        </div>

        <div className="flex flex-col sm:flex-row gap-4 [&>*]:flex-1">
          <SelectField2
            required={checked === 0}
            label="End Date Month:"
            options={
              formData?.end_y == new Date().getFullYear()
                ? [...monthOptions]?.splice(0, new Date().getMonth() + 1)
                : monthOptions
            }
            searchPlaceHolder="Search month"
            value={formData?.end_m || ""}
            onChange={(e: any) => {
              setFormData((prev: any) => ({
                ...prev,
                end_m: e,
              }));

              delete errors?.end_m;
            }}
            hint={errors?.end_m}
            placeholder="Choose month"
            disabled={
              checked === 1 || isLoading || formData?.status === "DECLINED"
            }
          />
          <SelectField2
            required={checked === 0}
            label="End Date Year:"
            options={yearOptions}
            searchPlaceHolder="Search year"
            value={formData?.end_y || ""}
            onChange={(e: any) => {
              setFormData((prev: any) => ({
                ...prev,
                end_y: e,
              }));
              if (
                e == new Date().getFullYear() &&
                formData?.end_m > new Date().getMonth() + 1
              ) {
                setFormData((prev: any) => ({
                  ...prev,
                  end_m: "",
                }));
              }
              delete errors?.end_y;
            }}
            hint={errors?.end_y}
            placeholder="Choose year"
            disabled={
              checked === 1 || isLoading || formData?.status === "DECLINED"
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateEditExperience;
