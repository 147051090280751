import React, { useEffect } from "react";

const useScrollOutside = (
  ref: React.RefObject<HTMLDivElement>,
  handler: () => void
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler();
    };

    window.addEventListener("scroll", listener, true); // Use capturing phase for scroll event
    return () => {
      window.removeEventListener("scroll", listener, true);
    };
  }, [ref, handler]);
};

export default useScrollOutside;
