import React, { useState } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/common/Modal";
import InputField from "../../../components/InputField";
import SelectField2 from "../../../components/SelectedField2";

const AddProductModal = ({
  openModal,
  setOpenModal,
}: {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
}) => {
  const [form, setForm] = useState({
    code: "",
    productName: "",
    lightingControl: "",
    trackShape: "",
    trackDimension: "",
    trackDriverQuantity: "",
    lightColor: "",
    bodyColor: "",
    reflectorColor: "",
    quantity: "",
    unitPrice: "",
    totalPrice: "",
    floor: "",
    area: "",
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Add form submission logic here
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {openModal && (
          <Modal
            clickOutsideToClose
            allowScroll
            header="Add a product"
            footer={
              <Button
                variant="secondary"
                label="Add to list"
                onClick={() => {}}
              />
            }
            handleCancel={() => setOpenModal(false)}
            minHeight="!w-[65vh]"
          >
            <div className="grid grid-cols-1 gap-4">
              <InputField
                label="Code"
                placeholder="Text"
                value={form.code}
                onChange={(value) => {
                  if (errors.code) {
                    delete errors.code; 
                    setErrors({ ...errors });
                  }
                  setForm({ ...form, code: value?.toUpperCase() });
                }}
                hint={errors?.code}
              />

              <InputField
                label="Product Name"
                placeholder="Text"
                value={form.productName}
                onChange={(value) => setForm({ ...form, productName: value })}
              />

              <div className="col-span-2">
                <InputField
                  label="Lighting Control"
                  placeholder="Text"
                  value={form.lightingControl}
                  onChange={(value) =>
                    setForm({ ...form, lightingControl: value })
                  }
                />
              </div>

              <div className="col-span-2">
                <InputField
                  label="Track Shape"
                  placeholder="Text"
                  value={form.trackShape}
                  onChange={(value) => setForm({ ...form, trackShape: value })}
                />
              </div>

              <InputField
                label="Track Dimension"
                placeholder="Text"
                value={form.trackDimension}
                onChange={(value) =>
                  setForm({ ...form, trackDimension: value })
                }
              />

              <InputField
                label="Track Driver Quantity"
                placeholder="Text"
                value={form.trackDriverQuantity}
                onChange={(value) =>
                  setForm({ ...form, trackDriverQuantity: value })
                }
              />

              <InputField
                label="Light Color"
                placeholder="Text"
                value={form.lightColor}
                onChange={(value) => setForm({ ...form, lightColor: value })}
              />

              <InputField
                label="Body Color"
                placeholder="Text"
                value={form.bodyColor}
                onChange={(value) => setForm({ ...form, bodyColor: value })}
              />

              <InputField
                label="Reflector Color"
                placeholder="Text"
                value={form.reflectorColor}
                onChange={(value) =>
                  setForm({ ...form, reflectorColor: value })
                }
              />

              <InputField
                label="Quantity"
                placeholder="Text"
                value={form.quantity}
                onChange={(value) => setForm({ ...form, quantity: value })}
              />

              <InputField
                label="Unit Price"
                placeholder="Text"
                value={form.unitPrice}
                onChange={(value) => setForm({ ...form, unitPrice: value })}
              />

              <InputField
                label="Total Price"
                placeholder="Text"
                value={form.totalPrice}
                onChange={(value) => setForm({ ...form, totalPrice: value })}
              />

              <SelectField2
                label="Floor"
                value={form.floor}
                options={[{ label: "123", value: "123" }]}
                onChange={(value) => setForm({ ...form, floor: value })}
                searchPlaceHolder="Search floor"
              />

              <SelectField2
                label="Area"
                value={form.area}
                options={[{ label: "123", value: "123" }]}
                onChange={(value) => setForm({ ...form, area: value })}
                searchPlaceHolder="Search area"
              />
            </div>
          </Modal>
        )}
      </form>
    </div>
  );
};

export default AddProductModal;
